import { useContext, useState } from 'react';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { StyledInput } from './AuthDialogs.elements';
import { FlexBox } from '../_shared';
import { AuthContext } from '../../auth-context';

const LoginForm = ({ onClose }) => {
  const { login } = useContext(AuthContext);
  const { palette } = useTheme();

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const handleLogin = () => {
    login();
    onClose();
  };

  return (
    <>
      <FlexBox fd="column" sx={{ width: '100%' }} gap="16px" mb={2.5}>
        <FormControl fullWidth>
          <StyledInput id="email" />
          <InputLabel htmlFor="email" type="email">
            Email
          </InputLabel>
        </FormControl>

        <FormControl fullWidth>
          <StyledInput
            id="password"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onClick={toggleShowPassword}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      textDecorationLine: 'underline',
                      textUnderlineOffset: 3,
                      fontSize: 14,
                      lineHeight: 1.4,
                      cursor: 'pointer',
                    }}
                  >
                    {showPassword ? 'Hide' : 'Show'}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
          <InputLabel htmlFor="password">Password</InputLabel>
        </FormControl>

        <Link href="#" sx={{ color: palette.neutral.darkgray, fontSize: 14 }}>
          Forgot Password?
        </Link>
      </FlexBox>

      <Button
        variant="contained"
        fullWidth
        sx={{ py: 1.625, mb: { xs: 3, md: 4 } }}
        onClick={handleLogin}
      >
        Login
      </Button>
    </>
  );
};

export default LoginForm;
