const Info = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity="0.15"
        y="6.10352e-05"
        width="32"
        height="32"
        rx="4"
        fill="#F6AE2D"
      />
      <path
        d="M16 6.00006C10.486 6.00006 6 10.4861 6 16.0001C6 21.5141 10.486 26.0001 16 26.0001C21.514 26.0001 26 21.5141 26 16.0001C26 10.4861 21.514 6.00006 16 6.00006ZM16 24.0001C11.589 24.0001 8 20.4111 8 16.0001C8 11.5891 11.589 8.00006 16 8.00006C20.411 8.00006 24 11.5891 24 16.0001C24 20.4111 20.411 24.0001 16 24.0001Z"
        fill="#F6AE2D"
      />
      <path
        d="M15 15.0001H17V21.0001H15V15.0001ZM15 11.0001H17V13.0001H15V11.0001Z"
        fill="#F6AE2D"
      />
    </svg>
  );
};

export default Info;
