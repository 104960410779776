import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Footer from '../../components/Layout/Footer';
import { StyledTabs, StyledTab } from './Home.elements';
import { TabPanel, a11yProps } from '../../components';
import { CovidInfo } from '../../components/_shared';
import { DomesticFlight, InternationalFlight } from './components';

const Home = () => {
  const { palette } = useTheme();
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, index) => setTabIndex(index);

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url("/home_illustartion.png")`,
          backgroundColor: 'primary.dark',
          backgroundSize: { xs: '600px', sm: 'contain' },
          backgroundPosition: { xs: 'right top', sm: 'center' },
          backgroundRepeat: 'no-repeat',
          minHeight: '100vh',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Container>
          <Box pt={{ xs: 11, md: 26 }} pb={10}>
            <Box mb={{ xs: 4, md: 7 }}>
              <Typography
                variant="h1"
                sx={{ mb: 2, color: palette.common.white, fontWeight: 700 }}
              >
                Travel made easy,
                <br />
                efficient yet affordable
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  color: palette.common.white,
                  display: { xs: 'none', md: 'block' },
                }}
              >
                Book your tickets with no hassles as promised.
              </Typography>
            </Box>

            <Box mb={2}>
              <StyledTabs value={tabIndex} onChange={handleTabChange}>
                <StyledTab label="International Flight" {...a11yProps(0)} />
                <StyledTab label="Domestic Flights" {...a11yProps(1)} />
              </StyledTabs>

              <TabPanel value={tabIndex} index={0}>
                <InternationalFlight />
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <DomesticFlight />
              </TabPanel>
            </Box>

            <CovidInfo />
          </Box>
        </Container>
      </Box>

      <Footer />
    </>
  );
};

export default Home;
