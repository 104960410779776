import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { CustomIconButton } from "./FlightSearchTool.styled";
import { useTheme } from "@mui/material/styles";
import {
  BiMap,
  BiSearch,
  BiRadioCircle,
  BiCalendarAlt,
  BiX,
  BiChevronLeft,
  BiChevronRight,
} from "react-icons/bi";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import { Autocomplete, StyledDatePicker, StyledDateInput } from "../_shared";
import { StyledButton } from "./FlightSearchTool.styled";

const AIRPORTS = [
  {
    city: "Kathmandu, Nepal",
    name: "Tribhuwan International Airport",
    code: "KTM",
  },
  {
    city: "London, United Kingdom",
    name: "London Heathrow Airport",
    code: "LHR",
  },
  {
    city: "London, United Kingdom",
    name: "London Stansted Airport",
    code: "STN",
  },
  {
    city: "London, United Kingdom",
    name: "London Gatwick Airport",
    code: "LGW",
  },
  { city: "London, United Kingdom", name: "London Luton Airport", code: "LTN" },
  { city: "London, United Kingdom", name: "London City Airport", code: "LCY" },
  {
    city: "London, United Kingdom",
    name: "London Southend Airport",
    code: "SEN",
  },
];

export const MultiCitySearch = ({ onDataChange = (data) => {} }) => {
  const { palette, breakpoints } = useTheme();
  const navigate = useNavigate();
  const smUp = useMediaQuery(breakpoints.up("sm"));

  const [flightRows, setFlightRows] = useState([
    {
      from: null,
      to: null,
      departureDate: null,
      departureDateOpen: false,
      disableDepartureDate: false,
    },
  ]);

  const toRefs = useRef([]);

  useEffect(() => {
    toRefs.current = toRefs.current.slice(0, flightRows.length);
  }, [flightRows]);

  useEffect(() => {
    onDataChange(
      flightRows.map(({ from, to, departureDate }) => ({
        from,
        to,
        departureDate,
      }))
    );
  }, [flightRows, onDataChange]);

  const openDepartureDate = (index) => () => {
    if (!flightRows[index].disableDepartureDate)
      setFlightRows([
        ...flightRows.slice(0, index),
        { ...flightRows[index], departureDateOpen: true },
        ...flightRows.slice(index + 1),
      ]);
  };

  const closeDepartureDate = (index) => () =>
    setFlightRows([
      ...flightRows.slice(0, index),
      { ...flightRows[index], departureDateOpen: false },
      ...flightRows.slice(index + 1),
    ]);

  const disableDepartureDate = (index, status) => () =>
    setFlightRows([
      ...flightRows.slice(0, index),
      { ...flightRows[index], disableDepartureDate: status },
      ...flightRows.slice(index + 1),
    ]);

  const setFlightRowFrom = (index) => (e, newValue) => {
    setFlightRows([
      ...flightRows.slice(0, index),
      { ...flightRows[index], from: newValue },
      ...flightRows.slice(index + 1),
    ]);

    toRefs.current[index]?.children[0].children[1].focus();
  };

  const setFlightRowTo = (index) => (e, newValue) =>
    setFlightRows([
      ...flightRows.slice(0, index),
      { ...flightRows[index], to: newValue },
      ...flightRows.slice(index + 1),
    ]);

  const setFlightRowDeparture = (index) => (newValue) => {
    flightRows[index].departureDate = newValue;
    setFlightRows(flightRows);
  };

  const incrementDate = (index) => () => {
    setFlightRows([
      ...flightRows.slice(0, index),
      {
        ...flightRows[index],
        departureDate: moment(flightRows[index].departureDate).add(1, "day"),
      },
      ...flightRows.slice(index + 1),
    ]);
  };

  const decrementDate = (index) => () => {
    if (moment(flightRows[index].departureDate).isAfter(new Date(), "day")) {
      setFlightRows([
        ...flightRows.slice(0, index),
        {
          ...flightRows[index],
          departureDate: moment(flightRows[index].departureDate).subtract(
            1,
            "day"
          ),
        },
        ...flightRows.slice(index + 1),
      ]);
    }
  };

  const handleAddFlight = () =>
    setFlightRows([
      ...flightRows,
      {
        from: null,
        to: null,
        departureDate: null,
        departureDateOpen: false,
        disableDepartureDate: false,
      },
    ]);

  const handleRemoveFlight = (index) => () =>
    setFlightRows(flightRows.filter((_, i) => i !== index));

  const handleSearch = () => {
    navigate("/result");
  };

  return (
    <Stack spacing={2}>
      {flightRows.map((flightRow, index) => (
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 1, md: 2 }}
          key={index}
        >
          {!smUp && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>Flight {index + 1}</Typography>

              <Button
                variant="text"
                sx={{
                  visibility: flightRows.length === 1 ? "hidden" : "unset",
                }}
                onClick={handleRemoveFlight(index)}
              >
                Remove
              </Button>
            </Stack>
          )}
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={1}
            flexGrow={1}
          >
            <Autocomplete
              id="from"
              options={AIRPORTS}
              value={flightRow.from}
              onChange={setFlightRowFrom(index)}
              placeholder="Where From?"
              startAdornment={
                <BiRadioCircle size={20} style={{ flexShrink: 0 }} />
              }
            />

            <Autocomplete
              ref={(input) => (toRefs.current[index] = input)}
              id="to"
              options={AIRPORTS}
              value={flightRow.to}
              onChange={setFlightRowTo(index)}
              placeholder="Where To?"
              startAdornment={<BiMap size={20} style={{ flexShrink: 0 }} />}
            />
          </Stack>

          <Box flex={1}>
            <StyledDatePicker
              toolbarTitle="Select Departure Date"
              open={flightRow.departureDateOpen}
              onClose={closeDepartureDate(index)}
              value={flightRow.departureDate}
              onChange={setFlightRowDeparture(index)}
              renderInput={({ inputRef, inputProps }) => {
                return (
                  <StyledDateInput
                    inputRef={inputRef}
                    inputProps={{
                      ...inputProps,
                      placeholder: "Departure Date",
                    }}
                    fullWidth
                    sx={{ paddingRight: 1 }}
                    onClick={openDepartureDate(index)}
                    startAdornment={
                      <BiCalendarAlt size={20} style={{ flexShrink: 0 }} />
                    }
                    endAdornment={
                      smUp && flightRow.departureDate ? (
                        <Stack
                          direction="row"
                          onMouseEnter={disableDepartureDate(index, true)}
                          onMouseLeave={disableDepartureDate(index, false)}
                        >
                          <CustomIconButton onClick={decrementDate(index)}>
                            <BiChevronLeft size={18} />
                          </CustomIconButton>
                          <CustomIconButton onClick={incrementDate(index)}>
                            <BiChevronRight size={18} />
                          </CustomIconButton>
                        </Stack>
                      ) : null
                    }
                  />
                );
              }}
            />
          </Box>

          {smUp && (
            <IconButton
              aria-label="delete"
              size="large"
              onClick={handleRemoveFlight(index)}
              sx={{
                visibility: flightRows.length === 1 ? "hidden" : "unset",
              }}
            >
              <BiX />
            </IconButton>
          )}
        </Stack>
      ))}

      <Box>
        <Button fullWidth={!smUp} variant="outlined" onClick={handleAddFlight}>
          Add Flight
        </Button>
      </Box>

      <Box
        display="flex"
        alignItems="flex-end"
        justifyContent="flex-end"
        sx={smUp ? { borderTop: 1, borderColor: "neutral.stroke", pt: 2 } : {}}
      >
        <StyledButton
          fullWidth={!smUp}
          startIcon={<BiSearch color={palette.common.white} size="1.25rem" />}
          onClick={handleSearch}
        >
          Search
        </StyledButton>
      </Box>
    </Stack>
  );
};

export default MultiCitySearch;
