import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.neutral.offwhite,
    color: theme.palette.common.black,
    whiteSpace: 'nowrap',
    padding: '12px 16px',
    border: 'none',
    '&:last-child': {
      borderRadius: '0 8px 8px 0',
    },
    '&:first-child': {
      borderRadius: '8px 0 0 8px',
    },
  },
  [`&.${tableCellClasses.body}`]: {
    padding: '16px',
    border: 'none',
    '&:last-child': {
      borderRadius: '0 8px 8px 0',
    },
    '&:first-child': {
      borderRadius: '8px 0 0 8px',
    },
  },
}));
