const Facebook = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_341_2691)">
        <path
          d="M8.83333 19.8889C4.11111 19.0556 0.5 14.9444 0.5 10C0.5 4.5 5 0 10.5 0C16 0 20.5 4.5 20.5 10C20.5 14.9444 16.8889 19.0556 12.1667 19.8889L11.6111 19.4444H9.38889L8.83333 19.8889Z"
          fill="#1877F2"
        />
        <path
          d="M14.3888 12.7778L14.8333 10H12.1666V8.05558C12.1666 7.27781 12.4444 6.66669 13.6666 6.66669H14.9444V4.11114C14.2221 4.00003 13.4444 3.88892 12.7221 3.88892C10.4444 3.88892 8.83325 5.2778 8.83325 7.77781V10H6.33325V12.7778H8.83325V19.8334C9.38881 19.9445 9.94436 20 10.4999 20C11.0555 20 11.611 19.9445 12.1666 19.8334V12.7778H14.3888Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_341_2691">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Facebook;
