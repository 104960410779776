import './sass/main.scss';
import { Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import Profile from './pages/Profile';

import Layout from './components/Layout';
import { GlobalReset } from './components/GlobalReset.styled';
import Result from './pages/Result';
import { RequireAuth } from './auth-context';
import PNRPage from './pages/PNRPage';
import PaymentPage from './pages/PaymentPage';
import SuccessPage from './pages/SuccessPage';

const App = () => {
  return (
    <div className="App">
      <GlobalReset />
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/result" element={<Result />} />
          <Route path="/demo-profile" element={<Profile />} />
          <Route path="/pnr" element={<PNRPage />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
