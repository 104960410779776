import { forwardRef } from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { BiX } from 'react-icons/bi';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog = ({ openDialog, onDialogChange, children }) => {
  const handleFilterDialogClose = () => onDialogChange(false);

  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={handleFilterDialogClose}
      TransitionComponent={Transition}
    >
      <Stack
        p={2}
        borderBottom={1}
        borderColor="neutral.stroke"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h3" fontWeight={'medium'}>
          Filter
        </Typography>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleFilterDialogClose}
          aria-label="close"
        >
          <BiX />
        </IconButton>
      </Stack>
      <Box p={2}>{children}</Box>

      <Stack
        p={2}
        borderTop={1}
        borderColor="neutral.stroke"
        direction="row"
        justifyContent="space-between"
        sx={{ position: 'fixed', bottom: 0, width: '100%' }}
      >
        <Button size="large" fullWidth onClick={handleFilterDialogClose}>
          Reset
        </Button>
        <Button
          variant="contained"
          size="large"
          fullWidth
          onClick={handleFilterDialogClose}
        >
          Done
        </Button>
      </Stack>
    </Dialog>
  );
};

export default FullScreenDialog;
