import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Typography
export const ItineraryText = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: theme.palette.common.black,
  lineHeight: '1.4',

  [theme.breakpoints.down('md')]: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));
export const ItineraryTextShrink = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: theme.palette.common.black,
  lineHeight: '1.4',
  [theme.breakpoints.down('md')]: {
    fontSize: '0.875rem',
  },
}));
export const ItineraryTextSmall = styled('span')(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.neutral.gray,
  lineHeight: '1.4',
}));

// Image
export const ItineraryAirlinesLogo = styled((props) => {
  return <img alt="" {...props} />;
})(({ theme }) => ({
  width: '36px',
  height: '36px',
  [theme.breakpoints.down('md')]: {
    width: '28px',
    height: '28px',
  },
}));

export const TimelineLine = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.neutral.stroke,
  flex: '1',
  width: '2px',
}));

export const LayoverDots = styled('span')(({ theme }) => ({
  borderColor: theme.palette.neutral.stroke,
  flex: '1',
  borderRightWidth: '4px',
  borderStyle: 'dotted',
  marginTop: '7px',
  marginBottom: '7px',
}));

// Layout
export const IniItineraryGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplate: 'repeat(5, auto) / 62px 36px 1fr',
  gridTemplateAreas: `
    'AirLogo Timeline DepLocation'
    'AirLogo Timeline DepDuration'
    'AirLogo Timeline AriLocation'
    'AirLogo LayoverDot FlightDetail'
    'Blank LayoverDot LayoverDetail'
  `,

  [theme.breakpoints.down('md')]: {
    gridTemplate: 'repeat(5, auto) / 36px 28px 1fr',
    gridTemplateAreas: `
    'AirLogo Timeline DepLocation'
    'AirLogo Timeline DepDuration'
    'AirLogo Timeline AriLocation'
    'AirLogo LayoverDot FlightDetail'
    'Blank LayoverDot LayoverDetail'
  `,
  },
}));

export const FinItineraryGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplate: 'repeat(4, auto) / 61px 36px 1fr',
  gridTemplateAreas: `
    'AirLogo Timeline DepLocation'
    'AirLogo Timeline DepDuration'
    'AirLogo Timeline AriLocation'
    'AirLogo Skip FlightDetail'
  `,

  [theme.breakpoints.down('md')]: {
    gridTemplate: 'repeat(5, auto) / 36px 28px 1fr',
    gridTemplateAreas: `
    'AirLogo Timeline DepLocation'
    'AirLogo Timeline DepDuration'
    'AirLogo Timeline AriLocation'
    'AirLogo LayoverDot FlightDetail'
    'Blank LayoverDot LayoverDetail'
  `,
  },
}));

export const AirLogo = styled(Box)({
  gridArea: 'AirLogo',
});
export const DepLocation = styled(Box)(({ theme }) => ({
  gridArea: 'DepLocation',
  display: 'flex',
  gap: '8px',
  alignItems: 'center',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'start',
    gap: '2px',
  },
}));
export const DepDuration = styled(Box)(({ theme }) => ({
  gridArea: 'DepDuration',
  marginTop: '16px',
  marginBottom: '16px',

  [theme.breakpoints.down('md')]: {
    marginTop: '12px',
    marginBottom: '12px',
  },
}));
export const AriLocation = styled(DepLocation)({
  gridArea: 'AriLocation',
});
export const FlightDetail = styled(Box)({
  gridArea: 'FlightDetail',
  display: 'flex',
  alignItems: 'center',
  marginTop: '16px',
});

export const TimelineContainer = styled(Box)(({ theme }) => ({
  gridArea: 'Timeline',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '20px',
  marginRight: '16px',
  gap: '6px',

  [theme.breakpoints.down('md')]: {
    '&:nth-child(1)': {
      outline: '1px solid red',
    },
  },
}));

export const LayoverDotsContainer = styled(Box)({
  gridArea: 'LayoverDot',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '20px',
  marginRight: '16px',
  gap: '6px',
});

export const LayoverContainer = styled(Box)(({ theme }) => ({
  gridArea: 'LayoverDetail',
  display: 'flex',
  alignItems: 'center',
  marginTop: '1.25rem',
  marginBottom: '1.25rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  borderTopWidth: '1px',
  borderBottomWidth: '1px',
  borderColor: theme.palette.neutral.stroke,
  borderStyle: 'solid',
}));
