const PlaneDown = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3334 6.49165L18.3334 8.07082L11.6667 13.3333L11.6667 16.6666C11.6667 17.1087 11.4912 17.5326 11.1786 17.8452C10.866 18.1577 10.4421 18.3333 10.0001 18.3333C9.55805 18.3333 9.13413 18.1577 8.82157 17.8452C8.50901 17.5326 8.33342 17.1087 8.33342 16.6666L8.33342 13.2458L1.66675 7.98331L1.66675 6.49248L8.33342 8.83415L8.33342 4.99998L5.83342 3.33331L5.83342 1.66665L10.0001 3.33331L14.1668 1.66665L14.1668 3.33331L11.6668 4.99998L11.6668 8.90415L18.3334 6.49165Z"
        fill="#484848"
      />
    </svg>
  );
};

export default PlaneDown;
