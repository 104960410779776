import DialogContent from "@mui/material/DialogContent";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconButton from "@mui/material/IconButton";
import { BiX, BiRightArrowAlt, BiDownArrowAlt } from "react-icons/bi";

import {
  SincereDialog,
  SincereDialogTitle,
  DateGridTableCell,
} from "./DateGridDialog.styled";

const columns = [
  "Mon, Feb 28",
  "Tue, Mar 1",
  "Wed, Mar 2",
  "Thu, Mar 3",
  "Fri, Mar 4",
  "Sat, Mar 5",
  "Sun, Mar 6",
];

function createData(dayM3, dayM2, dayM1, day0, dayP1, dayP2, dayP3, date2) {
  return { dayM3, dayM2, dayM1, day0, dayP1, dayP2, dayP3, date2 };
}

const rows = [
  createData(
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "Thu, Mar 8"
  ),
  createData(
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "Thu, Mar 8"
  ),
  createData(
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "Thu, Mar 8"
  ),
  createData(
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "Thu, Mar 8"
  ),
  createData(
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "Thu, Mar 8"
  ),
  createData(
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "Thu, Mar 8"
  ),
  createData(
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "NPR 137,180",
    "Thu, Mar 8"
  ),
];

const SELECTED_INDEX = 3;

const DateGridDialog = ({ openDialog, onDialogChange }) => {
  const { breakpoints } = useTheme();
  const smUp = useMediaQuery(breakpoints.up("sm"));

  const handleDateGridDialogClose = () => onDialogChange(false);

  return (
    <SincereDialog
      open={openDialog}
      onClose={handleDateGridDialogClose}
      aria-labelledby="date-grid"
      maxWidth="lg"
      fullScreen={!smUp}
    >
      <SincereDialogTitle id="data-grid-dialog-title">
        <Stack spacing={0.5}>
          <Typography variant="h3" fontWeight="medium" color="common.black">
            Flexible dates
          </Typography>
          {smUp && (
            <Typography variant="body1" color="neutral.gray">
              Compare prices for nearby days
            </Typography>
          )}
        </Stack>
        <IconButton
          aria-label="close"
          onClick={handleDateGridDialogClose}
          sx={
            smUp
              ? {
                  position: "absolute",
                  right: 16,
                  top: 16,
                  color: (theme) => theme.palette.grey[500],
                }
              : { p: 0, color: (theme) => theme.palette.grey[500] }
          }
        >
          <BiX size={smUp ? 32 : 24} />
        </IconButton>
      </SincereDialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <TableContainer elevation={0} component={Paper} sx={{ height: "100%" }}>
          <Table
            sx={{ minWidth: 650, height: "100%" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <DateGridTableCell colSpan={7}>
                  <Stack
                    direction="row"
                    spacing={0.5}
                    alignItems="center"
                    sx={{
                      pl: 1.25,
                      position: "sticky",
                      left: 0,
                      display: "inline-flex",
                    }}
                  >
                    Departure
                    <BiRightArrowAlt size="20px" />
                  </Stack>
                </DateGridTableCell>
                <DateGridTableCell
                  className="column-sticky"
                  rowSpan={2}
                  sx={{ verticalAlign: "bottom" }}
                >
                  <Stack
                    direction="row"
                    spacing={0.5}
                    alignItems="center"
                    sx={{ pl: 1.25 }}
                  >
                    Return
                    <BiDownArrowAlt size="20px" />
                  </Stack>
                </DateGridTableCell>
              </TableRow>
              <TableRow>
                {columns.map((column, index) => (
                  <DateGridTableCell align="center" key={index}>
                    {column}
                  </DateGridTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow hover key={index}>
                  <DateGridTableCell align="center">
                    {row.dayM3}
                  </DateGridTableCell>
                  <DateGridTableCell align="center">
                    {row.dayM2}
                  </DateGridTableCell>
                  <DateGridTableCell align="center">
                    {row.dayM1}
                  </DateGridTableCell>
                  <DateGridTableCell
                    align="center"
                    isSelected={index === SELECTED_INDEX}
                  >
                    {row.day0}
                  </DateGridTableCell>
                  <DateGridTableCell align="center">
                    {row.dayP1}
                  </DateGridTableCell>
                  <DateGridTableCell align="center">
                    {row.dayP2}
                  </DateGridTableCell>
                  <DateGridTableCell align="center">
                    {row.dayP3}
                  </DateGridTableCell>
                  <DateGridTableCell
                    align="center"
                    className="header column-sticky"
                    component="th"
                    scope="row"
                  >
                    {row.date2}
                  </DateGridTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </SincereDialog>
  );
};

export default DateGridDialog;
