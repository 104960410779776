import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  // Components
  FlightSummaryContainer,
  Tag,
  ExpandMore,
  ExpandMoreIcon,
  AirlinesLogo,
  BookPriceButton,
  StyledCardActionArea,

  // Typography
  CardTextLarge,
  CardTextSmall,
  FlightDuration,

  // Separator
  Line,
  Dot,
  CustomTooltip,
} from "./FlightCard.elements";
import {
  // Component
  ItineraryAirlinesLogo,

  // Typography
  ItineraryText,
  ItineraryTextShrink,
  ItineraryTextSmall,

  // Separator
  TimelineLine,
  LayoverDots,

  // Layouts
  IniItineraryGrid,
  FinItineraryGrid,
  TimelineContainer,
  AirLogo,
  DepLocation,
  DepDuration,
  AriLocation,
  FlightDetail,
  LayoverDotsContainer,
  LayoverContainer,
} from "./Itinerary.elements";
import { FlexBox } from "../_shared/Flex.styled";

import ArrowHead from "../../assets/icons/ArrowHead.icon";
import StopLine from "../../assets/icons/StopLine.icon";
import SuitcaseLarge from "../../assets/icons/SuitcaseLarge.icon";
import Baggage from "../../assets/icons/Baggage.icon";
import Meal from "../../assets/icons/Meal.icon";
import PlaneDown from "../../assets/icons/PlaneDown.icon";
import Origin from "../../assets/icons/Origin.icon";
import LocationPin from "../../assets/icons/LocationPin.icon";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Collapse from "@mui/material/Collapse";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const FlightCard = () => {
  const navigate = useNavigate();
  const { breakpoints, palette, shadows } = useTheme();
  const smUp = useMediaQuery(breakpoints.up("sm"));

  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  const disableParentRipple = (e) => e.stopPropagation();

  const handleBookFlight = (e) => {
    navigate("/pnr");
  };

  return (
    <FlightSummaryContainer>
      <Card
        sx={{
          borderRadius: "8px",
          width: "100%",
          boxShadow: { xs: shadows[2], md: shadows[0] },
        }}
        {...(smUp ? {} : { onClick: handleBookFlight })}
      >
        <StyledCardActionArea disableRipple={smUp} component="span">
          <Box p={{ xs: 2, md: 2.5 }}>
            {/* top tags */}
            <Box mb={2}>
              <Tag color="success">Cheapest, Fastest</Tag>
            </Box>

            {/* mid summary */}
            <Grid mb={2} container>
              <Grid item xs={12} md={9}>
                <Stack direction={"row"}>
                  {/* logo */}
                  <Box mr={{ xs: 1, md: 2 }}>
                    <AirlinesLogo src="/airlinesLogo.png" alt="airlines name" />
                  </Box>

                  {/* time and airports summary */}
                  <Grid
                    container
                    alignItems={"center"}
                    columns={{ xs: 3, md: 2 }}
                  >
                    <Grid item md={1}>
                      <Stack spacing={{ xs: 0.5, md: 1 }}>
                        <FlexBox
                          items="center"
                          sx={{ flex: "1", width: "100%" }}
                        >
                          <CustomTooltip
                            title="3:15 PM on  Sat, Mar 10"
                            placement="top-start"
                          >
                            <CardTextLarge sx={{ cursor: "pointer" }}>
                              3:15 PM
                            </CardTextLarge>
                          </CustomTooltip>
                          <Box
                            sx={{
                              display: { xs: "none", md: "flex" },
                              alignItems: "center",
                              flexGrow: "1",
                            }}
                          >
                            <Line />
                            <FlightDuration>20hr 15min</FlightDuration>
                            <Line />
                          </Box>
                        </FlexBox>
                        <CardTextSmall
                          sx={{ display: { xs: "none", md: "block" } }}
                        >
                          Kathmandu Tribhuwan Intl. (KTM)
                        </CardTextSmall>
                        <CardTextSmall
                          sx={{ display: { xs: "block", md: "none" } }}
                        >
                          KTM
                        </CardTextSmall>
                      </Stack>
                    </Grid>

                    {/* mobile arrow */}
                    <Grid item xs sx={{ display: { xs: "block", md: "none" } }}>
                      <Stack mx={2} spacing={0.4}>
                        <FlightDuration align="center">
                          20hr 15min
                        </FlightDuration>
                        <Stack direction={"row"} alignItems={"center"}>
                          <Line />
                          {
                            // true if there is one stop
                            true && (
                              <>
                                <StopLine />
                                <Line />
                                {
                                  // true if there are two stops
                                  false && (
                                    <>
                                      <StopLine />
                                      <Line />
                                    </>
                                  )
                                }
                              </>
                            )
                          }
                          <ArrowHead />
                        </Stack>
                        <FlightDuration align="center">1 Stop</FlightDuration>
                      </Stack>
                    </Grid>
                    <Grid item md={1}>
                      <Stack spacing={{ xs: 0.5, md: 1 }}>
                        <CustomTooltip
                          title="6:45 AM on  Sun, Mar 11"
                          placement="top-start"
                        >
                          <CardTextLarge sx={{ cursor: "pointer" }}>
                            6:45 AM
                          </CardTextLarge>
                        </CustomTooltip>
                        <CardTextSmall
                          sx={{ display: { xs: "none", md: "block" } }}
                        >
                          London Heathrow Airport (LHR)
                        </CardTextSmall>
                        <CardTextSmall
                          sx={{ display: { xs: "block", md: "none" } }}
                        >
                          LHR
                        </CardTextSmall>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>

              {/* Price */}
              <Grid item md={3} sx={{ display: { xs: "none", md: "block" } }}>
                <FlexBox fd="column" gap="8px" items="flex-end">
                  <CardTextLarge>NPR. 121,614</CardTextLarge>
                  <FlexBox items="center">
                    <FlexBox gap="4px" items="center">
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 1.8335C7.34073 1.8335 6.69626 2.02899 6.1481 2.39526C5.59994 2.76154 5.17269 3.28213 4.9204 3.89122C4.66811 4.5003 4.6021 5.17053 4.73072 5.81713C4.85933 6.46373 5.1768 7.05768 5.64298 7.52385C6.10915 7.99003 6.7031 8.3075 7.3497 8.43611C7.9963 8.56473 8.66652 8.49872 9.27561 8.24643C9.8847 7.99414 10.4053 7.56689 10.7716 7.01873C11.1378 6.47057 11.3333 5.8261 11.3333 5.16683C11.3333 4.28277 10.9821 3.43493 10.357 2.80981C9.7319 2.18469 8.88406 1.8335 8 1.8335ZM8 7.16683C7.60444 7.16683 7.21776 7.04953 6.88886 6.82977C6.55996 6.61001 6.30362 6.29765 6.15224 5.9322C6.00087 5.56674 5.96126 5.16461 6.03843 4.77665C6.1156 4.38869 6.30608 4.03232 6.58579 3.75262C6.86549 3.47291 7.22186 3.28243 7.60982 3.20526C7.99778 3.12809 8.39991 3.1677 8.76537 3.31907C9.13082 3.47045 9.44318 3.72679 9.66294 4.05569C9.8827 4.38459 10 4.77127 10 5.16683C10 5.69726 9.78929 6.20597 9.41421 6.58104C9.03914 6.95612 8.53043 7.16683 8 7.16683ZM14 14.5002V13.8335C14 12.5958 13.5083 11.4088 12.6332 10.5337C11.758 9.65849 10.571 9.16683 9.33333 9.16683H6.66667C5.42899 9.16683 4.242 9.65849 3.36683 10.5337C2.49167 11.4088 2 12.5958 2 13.8335V14.5002H3.33333V13.8335C3.33333 12.9494 3.68452 12.1016 4.30964 11.4765C4.93477 10.8514 5.78261 10.5002 6.66667 10.5002H9.33333C10.2174 10.5002 11.0652 10.8514 11.6904 11.4765C12.3155 12.1016 12.6667 12.9494 12.6667 13.8335V14.5002H14Z"
                          fill="#484848"
                        />
                      </svg>
                      <CardTextSmall>1</CardTextSmall>
                    </FlexBox>
                    <Dot />
                    <CardTextSmall>Round Trip</CardTextSmall>
                    <Dot />
                    <CardTextSmall>1 Stop</CardTextSmall>
                  </FlexBox>
                </FlexBox>
              </Grid>
            </Grid>

            {/* bot footer */}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                spacing="24px"
                alignItems="center"
                direction="row"
                sx={{ display: { xs: "none", md: "flex" } }}
              >
                <FlexBox gap="12px" items="center">
                  <FlexBox gap="4px" items="center">
                    <SuitcaseLarge />
                    <CardTextSmall>30 Kg</CardTextSmall>
                  </FlexBox>

                  <FlexBox gap="6px" items="center">
                    <FlexBox gap="4px" items="center">
                      <Baggage />
                      <CardTextSmall>7 Kg</CardTextSmall>
                    </FlexBox>
                    <CardTextSmall>per person</CardTextSmall>
                  </FlexBox>
                </FlexBox>

                <FlexBox gap="4px" items="center">
                  <Meal />
                  <CardTextSmall>Free Meal</CardTextSmall>
                </FlexBox>
              </Stack>

              <Stack direction="row" spacing="16px">
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  onTouchStart={disableParentRipple}
                  onMouseDown={disableParentRipple}
                  aria-expanded={expanded}
                  aria-label="Show detail"
                  variant="text"
                >
                  Flight Details
                  <ExpandMoreIcon>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.862 6.19531L7.99998 9.05731L5.13798 6.19531L4.19531 7.13798L7.99998 10.9426L11.8046 7.13798L10.862 6.19531Z"
                        fill="#1D76E2"
                      />
                    </svg>
                  </ExpandMoreIcon>
                </ExpandMore>

                <Button
                  variant="outlined"
                  onClick={handleBookFlight}
                  sx={{ display: { xs: "none", md: "block" } }}
                >
                  Book Flight
                </Button>
              </Stack>
              <Box sx={{ display: { xs: "block", md: "none" } }}>
                <CardTextLarge>NPR. 121,614</CardTextLarge>
              </Box>
            </Stack>
          </Box>
        </StyledCardActionArea>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box
            p={2}
            borderTop={1}
            borderColor={palette.neutral.stroke}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <Stack spacing="24px" alignItems="center" direction="row">
              <Stack spacing="12px" direction="row">
                <FlexBox gap="4px" items="center">
                  <SuitcaseLarge />
                  <CardTextSmall>30 Kg</CardTextSmall>
                </FlexBox>

                <FlexBox gap="4px" items="center">
                  <Baggage />
                  <CardTextSmall>7 Kg</CardTextSmall>
                </FlexBox>
              </Stack>

              <FlexBox gap="4px" items="center">
                <Meal />
                <CardTextSmall>Free Meal</CardTextSmall>
              </FlexBox>
            </Stack>
          </Box>
          <Box
            py={2.5}
            pl={{ xs: 2, md: 2.5 }}
            borderTop={1}
            borderColor={palette.neutral.stroke}
          >
            {/* ---------- FLight Before last ---------- */}
            <IniItineraryGrid>
              {/* Airlines logo */}
              <AirLogo>
                <ItineraryAirlinesLogo
                  src="/airlinesLogo.png"
                  alt="airlines name"
                />
              </AirLogo>

              {/* Departure Time & Airport */}
              <DepLocation>
                {/* Time */}
                <ItineraryText>3:15 PM</ItineraryText>

                <Dot sx={{ display: { xs: "none", md: "block" } }} />

                {/* Airport */}
                <ItineraryTextShrink>
                  Tribhuwan International Airport (KTM)
                </ItineraryTextShrink>
              </DepLocation>

              {/* Departure Duration */}
              <DepDuration>
                <ItineraryTextSmall>4hr 15min</ItineraryTextSmall>
              </DepDuration>

              {/* Arrival Time & Airport */}
              <AriLocation>
                {/* Time */}
                <ItineraryText>6:20 PM</ItineraryText>

                <Dot sx={{ display: { xs: "none", md: "block" } }} />

                {/* Airport */}
                <ItineraryTextShrink>
                  Abu Dhabi International Airport (AUH)
                </ItineraryTextShrink>
              </AriLocation>

              {/* Flight Details */}
              <FlightDetail>
                {/* Airlines */}
                <ItineraryTextSmall>Quatar Airways</ItineraryTextSmall>
                <Dot size="3px" bg={palette.neutral.gray} />
                {/* Class */}
                <ItineraryTextSmall>Economy</ItineraryTextSmall>
                <Dot size="3px" bg={palette.neutral.gray} />
                {/* Airplane Name */}
                <ItineraryTextSmall>3L 46</ItineraryTextSmall>
              </FlightDetail>

              {/* ---------- Layover Details ---------- */}
              <LayoverContainer>
                {/* Duration */}
                <ItineraryTextSmall>7hr 55min layover</ItineraryTextSmall>
                <Dot size="3px" bg={palette.neutral.gray} />

                {/* Location */}
                <ItineraryTextSmall>Abu Dhabi (AUH)</ItineraryTextSmall>
              </LayoverContainer>

              <TimelineContainer>
                <Box mt={0.125}>
                  <PlaneDown />
                </Box>
                <TimelineLine />
                <Box mb={{ xs: 2.625, md: 0.125 }}>
                  <Origin />
                </Box>
              </TimelineContainer>

              <LayoverDotsContainer>
                <LayoverDots />
              </LayoverDotsContainer>
            </IniItineraryGrid>

            {/* ---------- Last Flight ---------- */}
            <FinItineraryGrid>
              {/* Airlines logo */}
              <AirLogo>
                <ItineraryAirlinesLogo
                  src="/airlinesLogo.png"
                  alt="airlines name"
                />
              </AirLogo>

              {/* Departure Time & Airport */}
              <DepLocation>
                {/* Time */}
                <ItineraryText>2:15 AM</ItineraryText>
                <Dot sx={{ display: { xs: "none", md: "block" } }} />

                {/* Airport */}
                <ItineraryTextShrink>
                  Abu Dhabi International Airport (AUH)
                </ItineraryTextShrink>
              </DepLocation>

              {/* Departure Duration */}
              <DepDuration>
                <ItineraryTextSmall>7hr 30min</ItineraryTextSmall>
              </DepDuration>

              {/* Arrival Time & Airport */}
              <AriLocation>
                {/* Time */}
                <ItineraryText>6:45 AM</ItineraryText>
                <Dot sx={{ display: { xs: "none", md: "block" } }} />

                {/* Airport */}
                <ItineraryTextShrink>
                  London Heathrow Airport (LHR)
                </ItineraryTextShrink>
              </AriLocation>

              {/* Flight Details */}
              <FlightDetail>
                {/* Airlines */}
                <ItineraryTextSmall>Etihad</ItineraryTextSmall>
                <Dot size="3px" bg={palette.neutral.gray} />
                {/* Class */}
                <ItineraryTextSmall>Economy</ItineraryTextSmall>
                <Dot size="3px" bg={palette.neutral.gray} />
                {/* Airplane Name */}
                <ItineraryTextSmall>EY 11</ItineraryTextSmall>
              </FlightDetail>

              <TimelineContainer>
                <Box mt={0.125}>
                  <PlaneDown />
                </Box>
                <TimelineLine />
                <Box mb={{ xs: 2.625, md: 0.125 }}>
                  <LocationPin />
                </Box>
              </TimelineContainer>
            </FinItineraryGrid>
          </Box>

          <Box
            p={2}
            borderTop={1}
            borderColor={palette.neutral.stroke}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <BookPriceButton onClick={handleBookFlight} variant="contained">
              <Typography>Book Flight</Typography>
              <Typography>NPR. 121,614</Typography>
            </BookPriceButton>
          </Box>
        </Collapse>
      </Card>
    </FlightSummaryContainer>
  );
};

export default FlightCard;
