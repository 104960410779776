const Origin = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 10C15.8333 13.2159 13.2166 15.8334 9.99992 15.8334C6.78408 15.8334 4.16658 13.2159 4.16658 10C4.16658 6.78419 6.78408 4.16669 9.99992 4.16669C13.2166 4.16669 15.8333 6.78419 15.8333 10ZM5.83325 10C5.83325 12.2975 7.70242 14.1667 9.99992 14.1667C12.2974 14.1667 14.1666 12.2975 14.1666 10C14.1666 7.70252 12.2974 5.83335 9.99992 5.83335C7.70242 5.83335 5.83325 7.70252 5.83325 10Z"
        fill="#484848"
      />
    </svg>
  );
};

export default Origin;
