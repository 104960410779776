import { forwardRef } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { BiCheck, BiArrowBack } from "react-icons/bi";

import { StyledDropdownButton, StyledMenu } from "./Dropdown.elements";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Dropdown = ({
  id,
  anchorEl,
  data,
  label,
  value,
  open,
  onClose,
  onClick,
  onMenuClick,
}) => {
  const { palette, breakpoints } = useTheme();
  const smUp = useMediaQuery(breakpoints.up("sm"));

  const onMenuItemClick = (index) => (event) => onMenuClick(event, index);

  return (
    <>
      <StyledDropdownButton onClick={onClick}>
        {value === null ? label : data[value]}
      </StyledDropdownButton>

      {smUp ? (
        <StyledMenu
          id={id}
          anchorEl={anchorEl}
          open={open}
          onClose={onClose}
          PaperProps={{ style: { maxHeight: 500 } }}
        >
          {data.map((option, index) => (
            <MenuItem
              key={option}
              selected={index === value}
              onClick={onMenuItemClick(index)}
            >
              {index === value ? (
                <>
                  <ListItemIcon>
                    <BiCheck color={palette.primary.main} />
                  </ListItemIcon>
                  {option}
                </>
              ) : (
                <ListItemText inset>{option}</ListItemText>
              )}
            </MenuItem>
          ))}
        </StyledMenu>
      ) : (
        <Dialog
          fullScreen
          open={open}
          onClose={onClose}
          TransitionComponent={Transition}
        >
          <Stack
            p={2}
            borderBottom={1}
            borderColor="neutral.stroke"
            direction="row"
            alignItems="center"
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
              sx={{ mr: 0.5 }}
            >
              <BiArrowBack color={palette.neutral.gray} />
            </IconButton>
            <Typography variant="body1">{label}</Typography>
          </Stack>
          <Box p={1}>
            {data.map((option, index) => (
              <MenuItem
                key={option}
                selected={index === value}
                onClick={onMenuItemClick(index)}
              >
                {index === value ? (
                  <>
                    <ListItemIcon>
                      <BiCheck color={palette.primary.main} />
                    </ListItemIcon>
                    {option}
                  </>
                ) : (
                  <ListItemText inset>{option}</ListItemText>
                )}
              </MenuItem>
            ))}
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default Dropdown;
export * from "./TicketsDropdown";
