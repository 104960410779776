import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Header } from './Header';
import { Body } from './Body';
import { Footer } from './Footer';

export const SincereCard = styled(Paper)(({ theme }) => ({
  background: theme.palette.common.white,
  borderRadius: '8px',
  position: 'relative',
  boxShadow: 'none',
}));

SincereCard.Header = Header;
SincereCard.Body = Body;
SincereCard.Footer = Footer;
