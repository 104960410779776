import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { styled } from "@mui/material/styles";
import { BiCaretDown } from "react-icons/bi";

export const StyledDropdownButton = styled((props) => (
  <Button variant="text" endIcon={<BiCaretDown size="1rem" />} {...props} />
))(({ theme }) => ({
  color: theme.palette.neutral.darkgray,
  "& .MuiButton-startIcon": {
    marginRight: 8,
  },
}));

export const StyledMenu = styled((props) => <Menu elevation={0} {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      minWidth: 220,
    },
    "& .MuiMenuItem-root": {
      "&.Mui-selected": {
        color: theme.palette.primary.main,
      },
    },
  })
);

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: 4,
  background: theme.palette.primary.faded,
  color: theme.palette.primary.main,
  "&.Mui-disabled": {
    background: theme.palette.neutral.offwhite,
    color: theme.palette.neutral.gray,
  },
  "&:hover": {
    background: theme.palette.primary.fadedHover,
  },
}));