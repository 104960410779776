import { useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import { StyledToggleButtonGroup } from "./index.elements";
import { Dropdown, TicketsDropdown } from "../../../components/_shared";
import {
  OneWaySearch,
  RoundTripSearch,
} from "../../../components/FlightSearchTool";

const NATIONALITIES = ["American", "Nepali", "Ukrainian"];

const DomesticFlight = () => {
  const [anchorTraveller, setAnchorTraveller] = useState(null);
  const openTraveller = Boolean(anchorTraveller);

  const [anchorNationality, setAnchorNationality] = useState(null);
  const [selectedNationality, setSelectedNationality] = useState(null);
  const openNationality = Boolean(anchorNationality);

  const [tripType, setTripType] = useState("one");

  const handleClickTravellerItem = (event) =>
    setAnchorTraveller(event.currentTarget);

  const [travellers, setTravellers] = useState({
    adults: 1,
    children: 0,
    infants: 0,
  });

  const handleTravellerClose = () => setAnchorTraveller(null);
  const handleTravellerDone = ({ adults, children, infants }) => {
    setTravellers({ adults, children, infants });
    setAnchorTraveller(null);
  };

  const handleClickNationalityItem = (event) =>
    setAnchorNationality(event.currentTarget);
  const handleNationalityChange = (event, index) => {
    setSelectedNationality(index);
    setAnchorNationality(null);
  };
  const handleNationalityClose = () => setAnchorNationality(null);

  const handleChange = (event, newAlignment) => setTripType(newAlignment);

  return (
    <Box sx={{ background: "#fff", borderRadius: "8px" }} p={2}>
      <Stack spacing={2}>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent="space-between"
        >
          <StyledToggleButtonGroup
            color="primary"
            value={tripType}
            exclusive
            onChange={handleChange}
            sx={{ mb: { xs: 1, md: "unset" } }}
          >
            <ToggleButton value="one" disabled={tripType === "one"}>
              One-way
            </ToggleButton>
            <ToggleButton value="round" disabled={tripType === "round"}>
              Round trip
            </ToggleButton>
          </StyledToggleButtonGroup>

          <Stack direction="row" spacing={1}>
            <TicketsDropdown
              travellers={travellers}
              onClick={handleClickTravellerItem}
              anchorEl={anchorTraveller}
              open={openTraveller}
              onDone={handleTravellerDone}
              onCancel={handleTravellerClose}
            />

            <Dropdown
              id="nationality-menu"
              data={NATIONALITIES}
              label="Nationality"
              value={selectedNationality}
              anchorEl={anchorNationality}
              open={openNationality}
              onClose={handleNationalityClose}
              onClick={handleClickNationalityItem}
              onMenuClick={handleNationalityChange}
            />
          </Stack>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          {tripType === "round" && <RoundTripSearch />}

          {tripType === "one" && <OneWaySearch />}
        </Box>
      </Stack>
    </Box>
  );
};

export default DomesticFlight;
