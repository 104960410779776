import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import { StyledImage, StyledIconButton } from "./Footer.element";
import FacebookIcon from "../../../assets/icons/FacebookFooter.icon";
import InstagramIcon from "../../../assets/icons/InstagramFooter.icon";

const Footer = () => {
  const { palette } = useTheme();
  return (
    <footer style={{ background: palette.common.white }}>
      <Container sx={{ py: 10 }}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={4} lg={5}>
            <Stack spacing={3} mb={5}>
              <Link href="/">
                <StyledImage src="/logo.png" />
              </Link>
              <Typography sx={{ color: palette.neutral.darkgray }}>
                Monday - Friday: 10am to 6pm
                <br />
                Saturday: 11am to 3pm
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} md={8} lg={7}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ xs: 5, md: 3 }}
              justifyContent={{ xs: "flex-start", md: "space-between" }}
            >
              <Stack>
                <Typography variant="footerHeading4">Location</Typography>

                <Typography sx={{ mb: 1, color: palette.neutral.darkgray }}>
                  Ganesthan, Balaju,
                  <br />
                  Kathmandu, Nepal
                </Typography>

                <Link
                  sx={{
                    textDecoration: "none",
                    fontSize: 14,
                    fontWeight: 700,
                    cursor: "pointer",
                  }}
                  href="https://goo.gl/maps/Aoxn53hWTjaNjEqQ7"
                  target="_blank"
                >
                  View on map
                </Link>
              </Stack>

              <Stack>
                <Typography variant="footerHeading4">Get in Touch</Typography>

                <Link
                  sx={{
                    color: palette.neutral.darkgray,
                    lineHeight: 1.7,
                    textDecoration: "none",
                    mb: 1,
                  }}
                  href="tel:+97714357633"
                >
                  +977 1 4357633
                </Link>
                <Link
                  sx={{
                    color: palette.neutral.darkgray,
                    lineHeight: 1.7,
                    textDecoration: "none",
                  }}
                  href="tel:+97714356924"
                >
                  +977 1 4356924
                </Link>
              </Stack>

              <Stack>
                <Typography variant="footerHeading4">Any Questions?</Typography>

                <Typography sx={{ mb: 2 }}>
                  Email us at{" "}
                  <Link
                    sx={{
                      lineHeight: 1.7,
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                    href="mailto:info@sinceretravels.com"
                  >
                    info@sinceretravels.com
                  </Link>
                </Typography>

                <Stack direction="row" spacing={1}>
                  <StyledIconButton aria-label="facebook link">
                    <FacebookIcon />
                  </StyledIconButton>
                  <StyledIconButton aria-label="instagram link">
                    <InstagramIcon />
                  </StyledIconButton>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
