import { useState } from 'react';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { SincereCard, StyledTableCell } from '../../../components/_shared';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';

function createData(
  sNo,
  issuedDate,
  airlines,
  segment,
  pnr,
  passengers,
  departure,
  total,
  payment,
  ticketStatus
) {
  return {
    sNo,
    issuedDate,
    airlines,
    segment,
    pnr,
    passengers,
    departure,
    total,
    payment,
    ticketStatus,
  };
}

const rows = [
  createData(
    '1',
    '29/01/2021',
    'Nepal Airlines',
    'Segment',
    '671192',
    'Hari Bahadur',
    '10/02/2021',
    '61,000',
    '61,000',
    'Paid'
  ),
  createData(
    '2',
    '29/01/2021',
    'Nepal Airlines',
    'Segment',
    '671192',
    'Hari Bahadur',
    '10/02/2021',
    '61,000',
    '61,000',
    'Paid'
  ),
  createData(
    '3',
    '29/01/2021',
    'Nepal Airlines',
    'Segment',
    '671192',
    'Hari Bahadur',
    '10/02/2021',
    '61,000',
    '61,000',
    'Paid'
  ),
  createData(
    '4',
    '29/01/2021',
    'Nepal Airlines',
    'Segment',
    '671192',
    'Hari Bahadur',
    '10/02/2021',
    '61,000',
    '61,000',
    'Paid'
  ),
];

const MyTicket = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Typography variant="h2" fontWeight="medium" mb={4}>
        My Tickets
      </Typography>
      <SincereCard>
        <SincereCard.Header>
          <Typography variant="h4" sx={{ fontWeight: 'medium' }}>
            Tickets
          </Typography>
        </SincereCard.Header>
        <SincereCard.Body>
          <TableContainer component={Paper} elevation={0}>
            <Table stickyHeader aria-label="ticket table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Sno.</StyledTableCell>
                  <StyledTableCell>Issued Date</StyledTableCell>
                  <StyledTableCell>Airlines</StyledTableCell>
                  <StyledTableCell>Segment</StyledTableCell>
                  <StyledTableCell>PNR</StyledTableCell>
                  <StyledTableCell>Passengers</StyledTableCell>
                  <StyledTableCell>Departure</StyledTableCell>
                  <StyledTableCell>Total</StyledTableCell>
                  <StyledTableCell>Payment</StyledTableCell>
                  <StyledTableCell>Ticket Status</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  '&.MuiTableBody-root:before': {
                    content: '"@"',
                    display: 'block',
                    lineHeight: '16px',
                    textIndent: '-99999px',
                  },
                }}
              >
                {rows.map((row, index) => (
                  <TableRow
                    key={index}
                    hover={true}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <StyledTableCell sx={{ whiteSpace: 'nowrap' }}>
                      {row.sNo}
                    </StyledTableCell>
                    <StyledTableCell sx={{ whiteSpace: 'nowrap' }}>
                      {row.issuedDate}
                    </StyledTableCell>
                    <StyledTableCell sx={{ whiteSpace: 'nowrap' }}>
                      {row.airlines}
                    </StyledTableCell>
                    <StyledTableCell sx={{ whiteSpace: 'nowrap' }}>
                      {row.segment}
                    </StyledTableCell>
                    <StyledTableCell sx={{ whiteSpace: 'nowrap' }}>
                      {row.pnr}
                    </StyledTableCell>
                    <StyledTableCell sx={{ whiteSpace: 'nowrap' }}>
                      {row.passengers}
                    </StyledTableCell>
                    <StyledTableCell sx={{ whiteSpace: 'nowrap' }}>
                      {row.departure}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ whiteSpace: 'nowrap' }}
                      align="right"
                    >
                      {' '}
                      {row.total}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ whiteSpace: 'nowrap' }}
                      align="right"
                    >
                      {' '}
                      {row.payment}
                    </StyledTableCell>
                    <StyledTableCell sx={{ whiteSpace: 'nowrap' }}>
                      {row.ticketStatus}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </SincereCard.Body>
      </SincereCard>
    </>
  );
};

export default MyTicket;
