import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

export const StyledTabs = styled(Tabs)({
  paddingLeft: 0,
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

export const StyledTab = styled(Tab)(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.common.white,
  '&.Mui-selected': {
    color: theme.palette.neutral.black,
    background: theme.palette.common.white,
    ...theme.typography.body2,
    fontWeight: 700,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    position: 'relative',
    overflow: 'unset',

    '&::after': {
      content: '""',
      position: 'absolute',
      width: 8,
      height: 8,
      backgroundImage: `url("/smoothTab.svg")`,
      bottom: 0,
      left: '100%',
    },
  },

  '&:nth-of-type(n+2).Mui-selected::before': {
    content: '""',
    position: 'absolute',
    width: 8,
    height: 8,
    backgroundImage: `url("/smoothTab.svg")`,
    bottom: 0,
    right: '100%',
    transform: 'scaleX(-1)',
  },
}));
