import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CheckCircle from '../../assets/icons/CheckCircle.icon';

import { ButtonSemiLarge } from './SuccessPage.elements';

const SuccessPage = () => {
  return (
    <Container className="st-no-footer" fixed sx={{ mt: 5 }}>
      <Stack alignItems={'center'} pt={{ xs: 8, sm: 15, md: 20 }}>
        <CheckCircle size="96" />
        <Typography variant="h3" mt={3} fontWeight={'medium'} align="center">
          Thank you for choosing Sincere Travels
        </Typography>
        <Typography
          variant="bodyLarge"
          mt={{ xs: 1, sm: 2 }}
          maxWidth={450}
          align="center"
        >
          Your round trip from <b>Kathmandu to London</b> has been booked
          successfully
        </Typography>

        <Stack spacing={2} width={250} mt={5}>
          <ButtonSemiLarge fullWidth variant="contained">
            View Booking
          </ButtonSemiLarge>
          <ButtonSemiLarge fullWidth>Go back to homepage</ButtonSemiLarge>
        </Stack>
      </Stack>
    </Container>
  );
};

export default SuccessPage;
