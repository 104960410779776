const Meal = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99992 8.33333H8.33325V2.5H6.66658V8.33333H4.99992V2.5H3.33325V9.16667C3.33325 10.545 4.45492 11.6667 5.83325 11.6667H6.66658V17.5H8.33325V11.6667H9.16658C10.5449 11.6667 11.6666 10.545 11.6666 9.16667V2.5H9.99992V8.33333ZM15.8332 2.5H14.9999C14.0341 2.5 13.3333 3.55167 13.3333 5V11.6667H14.9999V17.5H16.6666V3.33333C16.6666 3.11232 16.5788 2.90036 16.4225 2.74408C16.2662 2.5878 16.0543 2.5 15.8332 2.5Z"
        fill="#484848"
      />
    </svg>
  );
};

export default Meal;
