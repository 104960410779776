import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { IconContext } from "react-icons";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ErrorBoundary } from "./components";
import { AuthContextProvider } from "./auth-context";
import { SincereTheme } from "./sincereTheme";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ErrorBoundary>
        <ThemeProvider theme={SincereTheme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <IconContext.Provider
              value={{
                color: SincereTheme.palette.neutral.darkgray,
                size: "1.5rem",
              }}
            >
              <AuthContextProvider>
                <App />
              </AuthContextProvider>
            </IconContext.Provider>
          </LocalizationProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
