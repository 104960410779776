const Plane = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.414 13.7779L2 15.1919L6.949 17.3129L9.071 22.2629L10.485 20.8489L9.778 17.3129L13.091 13.9999L16.701 21.7039L18.04 20.3649L16.85 10.2419L19.678 7.41294C19.869 7.22845 20.0214 7.00776 20.1262 6.76375C20.231 6.51974 20.2862 6.2573 20.2885 5.99174C20.2908 5.72619 20.2402 5.46283 20.1396 5.21703C20.0391 4.97124 19.8906 4.74794 19.7028 4.56015C19.515 4.37237 19.2917 4.22386 19.0459 4.1233C18.8001 4.02274 18.5368 3.97213 18.2712 3.97444C18.0056 3.97675 17.7432 4.03192 17.4992 4.13674C17.2552 4.24156 17.0345 4.39392 16.85 4.58494L13.947 7.48794L3.824 6.29694L2.559 7.56294L10.203 11.2329L6.95 14.4859L3.414 13.7779Z"
        fill="#484848"
      />
    </svg>
  );
};

export default Plane;
