import { Outlet, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from './Header';

const Layout = () => {
  const { pathname } = useLocation();
  const { breakpoints } = useTheme();

  const mdUp = useMediaQuery(breakpoints.up('md'));

  const isResultPage = pathname === '/result';
  return (
    <>
      {/* Hide Header in result page for mobile */}
      {(!isResultPage || mdUp) && <Header />}
      <Outlet />
    </>
  );
};

export default Layout;
