import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const FlexBox = styled(Box)`
  display: flex;
  flex-direction: ${({ fd }) => fd || 'row'};
  gap: ${({ gap }) => gap || '0'};
  align-items: ${({ items }) => items || 'flex-start'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
`;
