import { createTheme } from '@mui/material/styles';
import shadows from '@mui/material/styles/shadows';

export const SincereTheme = createTheme({
  // spacing: 16,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1248,
      xl: 1536,
    },
  },
  palette: {
    common: {
      black: '#212121',
    },
    primary: {
      main: '#1D76E2',
      dark: '#001e74',
      faded: '#E4EFFC',
      fadedHover: '#D2E4F9',
    },
    error: {
      main: '#D33E43',
    },
    warning: {
      main: '#F6AE2D',
    },
    success: {
      main: '#11A871',
    },
    neutral: {
      white: '#FFF',
      offwhite: '#F3F5F7',
      offgray: '#EDEFF0',
      stroke: '#DADCE0',
      placeholder: '#AAAAAA',
      gray: '#757575',
      darkgray: '#484848',
      black: '#212121',
    },
  },
  typography: {
    fontFamily: 'Lato',
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    fontWeightBold: 900,
    footerHeading4: {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: 1.4,
      marginBottom: 16,
    },
    h1: {
      fontSize: '3.8125rem',
      lineHeight: '104%',

      '@media (max-width: 600px)': {
        fontSize: '1.6875rem',
        lineHeight: '140%',
      },
    },
    h2: {
      fontSize: '1.938rem',
      lineHeight: '140%',

      '@media (max-width: 600px)': {
        fontSize: '1.375rem',
      },
    },
    h3: {
      fontSize: '1.562rem',
      lineHeight: '140%',

      '@media (max-width: 600px)': {
        fontSize: '1.125rem',
      },
    },
    h4: {
      fontSize: '1.250rem',
      lineHeight: '140%',
    },
    bodyLarge: {
      fontSize: '1.125rem',
      lineHeight: '150%',
      '@media (max-width: 600px)': {
        fontSize: '0.875rem',
      },
    },
    bodyShrink: {
      fontSize: '1rem',
      lineHeight: '140%',

      '@media (max-width: 600px)': {
        fontSize: '0.875rem',
      },
    },
    inputLabel: {
      fontSize: '1rem',
      lineHeight: '140%',
    },
    inputHelperText: {
      fontSize: '0.875rem',
      lineHeight: '140%',
      color: '#757575',
    },
    subtitle1: {
      fontSize: '0.875rem',
      lineHeight: '140%',
      color: '#AAAAAA',
      textTransform: 'uppercase',
      letterSpacing: '0.04em',
      fontWeight: 700,
    },
  },
  components: {
    // Name of the component
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontWeight: 700,
          '&.Mui-active': {
            fontWeight: 700,
            color: '#212121',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          textTransform: 'capitalize',
        },
        outlined: {
          borderColor: '#DADCE0',
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
          '&:hover': {
            borderColor: '#DADCE0',
          },
        },
        contained: {
          background: '#1D76E2',
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
          boxShadow: 'none',
        },
        startIcon: { marginRight: 12 },
        endIcon: { marginLeft: 4 },
        sizeLarge: {
          padding: '15px 32px',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: { textTransform: 'none' },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {},
        paper: {
          borderRadius: 8,
        },
        list: {
          padding: 4,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          height: 52,
          paddingLeft: 8,
          paddingRight: 8,

          '&.Mui-selected': {
            background: '#F3F5F7',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          paddingLeft: 20,
          paddingRight: 20,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover:hover': {
            background: '#F3F5F7',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column-reverse',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#484848',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          paddingLeft: '32px !important',
          paddingTop: '14px !important',
          paddingBottom: '14px !important',
        },
        input: {
          paddingRight: 16,
        },
        root: {
          '& .MuiOutlinedInput-root': {
            padding: 0,
            paddingLeft: 12,
          },
        },
      },
    },
  },
  shadows: {
    ...shadows, // Mui default Shadows
    0: 'none',
    1: '0 2px 6px rgba(33, 33, 33, 0.08)',
    2: '0 4px 12px rgba(33, 33, 33, 0.08)',
  },
});
