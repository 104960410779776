import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';

import { DialogImage, ButtonSemiLarge } from './SessionExpiredModal.elements';

const SessionExpiredModal = ({ openDialog, onDialogChange }) => {
  const handleSessionExpiredModalOpen = () => {
    onDialogChange(false);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleSessionExpiredModalOpen}
      aria-labelledby="session expired modal"
    >
      <Stack justifyContent={'center'} p={4}>
        <DialogImage src="/session-expired-illustration.png" alt="" />

        <Stack maxWidth={240} spacing={2} mt={3} mx={3.75}>
          <Typography
            variant="h3"
            fontWeight={'medium'}
            align="center"
            color={'common.black'}
          >
            Your Session has Expired
          </Typography>
          <Typography variant="body1" align="center" color={'neutral.darkgray'}>
            To see the latest available flights, please refresh the results.
          </Typography>
        </Stack>

        <Stack spacing={0.5} mt={3}>
          <ButtonSemiLarge
            variant="contained"
            onClick={handleSessionExpiredModalOpen}
          >
            Refresh
          </ButtonSemiLarge>
          <ButtonSemiLarge onClick={handleSessionExpiredModalOpen}>
            Go back to homepage
          </ButtonSemiLarge>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default SessionExpiredModal;
