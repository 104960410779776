import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
  FlexBox,
  SincereCard,
  DividerTitle,
  StyledList,
  StyledListItem,
} from '../../../components/_shared';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import TicketContained from '../../../assets/icons/TicketContained.icon';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { BiGlobe, BiCalendarAlt, BiIdCard } from 'react-icons/bi';

import { PaymentLogo } from './PaymentDetail.styled';

const PaymentDetail = ({ infoUpdate }) => {
  const { palette } = useTheme();
  const theme = useTheme();
  const lgMatches = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Traveller Details */}
      <DividerTitle>
        <Typography variant="subtitle1">Traveller’s Information</Typography>
      </DividerTitle>
      <SincereCard sx={{ mb: { xs: '16px', md: '16px' } }}>
        <SincereCard.Header>
          <Typography variant="h4" sx={{ fontWeight: 'medium' }}>
            Mrs. Jane Cooper
          </Typography>
          <Button variant="text" onClick={infoUpdate}>
            Update
          </Button>
        </SincereCard.Header>
        <SincereCard.Body>
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={4}>
              <FlexBox gap="12px" items="center">
                <BiGlobe size={24} color={palette.neutral.gray} />
                <FlexBox fd="column">
                  <Typography
                    fontSize="13px"
                    variant="subtitle1"
                    color={palette.common.black}
                  >
                    Nationality
                  </Typography>
                  <Typography variant="body1" color={palette.common.black}>
                    Nepalese
                  </Typography>
                </FlexBox>
              </FlexBox>
            </Grid>

            <Grid item xs={12} md={4}>
              <FlexBox gap="12px" items="center">
                <BiCalendarAlt size={24} color={palette.neutral.gray} />
                <FlexBox fd="column">
                  <Typography
                    fontSize="13px"
                    variant="subtitle1"
                    color={palette.common.black}
                  >
                    Date of Birth
                  </Typography>
                  <Typography variant="body1" color={palette.common.black}>
                    28/03/1987
                  </Typography>
                </FlexBox>
              </FlexBox>
            </Grid>

            <Grid item xs={12} md={4}>
              <FlexBox gap="12px" items="center">
                <BiIdCard size={24} color={palette.neutral.gray} />
                <FlexBox fd="column">
                  <Typography
                    fontSize="13px"
                    variant="subtitle1"
                    color={palette.common.black}
                  >
                    Document ID
                  </Typography>
                  <FlexBox gap="8px" items="flex-end">
                    <Typography variant="body1" color={palette.common.black}>
                      67856781
                    </Typography>
                    <Typography variant="body2" color={palette.neutral.gray}>
                      06/22/2024
                    </Typography>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </Grid>
          </Grid>
        </SincereCard.Body>
      </SincereCard>
      <Paper
        elevation={0}
        sx={{ borderRadius: '8px', mb: 4, overflow: 'hidden' }}
      >
        <FlexBox items="center" gap="12px" p={2.5}>
          <TicketContained />
          <Typography color={'neutral.darkgray'}>
            Your ticket will be sent to:{' '}
            <Typography component="span" color={palette.primary.main}>
              janeCooper@gmail.com
            </Typography>
          </Typography>
        </FlexBox>
      </Paper>
      {/* Payment Form */}
      <DividerTitle>
        <Typography variant="subtitle1">Payment details</Typography>
      </DividerTitle>
      <SincereCard sx={{ mb: { xs: '24px', md: '32px' } }}>
        <SincereCard.Header>
          <Typography variant="h4" sx={{ fontWeight: 'medium' }}>
            Select payment method
          </Typography>
        </SincereCard.Header>
        <SincereCard.Body sx={{ pt: 0, pb: 1 }}>
          <Grid container spacing={2.5}>
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="payment-group"
                >
                  <FormControlLabel
                    value="visaMaster"
                    control={<Radio />}
                    label={
                      <PaymentLogo
                        src="/payment/visaMaster.png"
                        alt="airlines name"
                      />
                    }
                  />
                  <FormControlLabel
                    value="esewa"
                    control={<Radio />}
                    label={
                      <PaymentLogo
                        src="/payment/esewa.png"
                        alt="airlines name"
                      />
                    }
                  />
                  <FormControlLabel
                    value="khalti"
                    control={<Radio />}
                    label={
                      <PaymentLogo
                        src="/payment/khalti.png"
                        alt="airlines name"
                      />
                    }
                  />
                  <FormControlLabel
                    value="fonepay"
                    control={<Radio />}
                    label={
                      <PaymentLogo
                        src="/payment/fonepay.png"
                        alt="airlines name"
                      />
                    }
                  />
                  <FormControlLabel
                    value="himalayanbank"
                    control={<Radio />}
                    label={
                      <PaymentLogo
                        src="/payment/himalayanbank.png"
                        alt="airlines name"
                      />
                    }
                  />
                  <FormControlLabel
                    value="connectIps"
                    control={<Radio />}
                    label={
                      <PaymentLogo src="/payment/ips.png" alt="airlines name" />
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </SincereCard.Body>
      </SincereCard>
      <Box mb={4}>
        <Typography variant="h4" component="h4" fontWeight="medium">
          By clicking Pay:
        </Typography>
        <StyledList>
          <StyledListItem>
            I accept{' '}
            <Link href="#" underline="hover">
              <Typography component="span" variant="body1">
                terms and condition of Sincere Travels
              </Typography>
            </Link>
            .
          </StyledListItem>
          <StyledListItem>
            I agree to the use of my data in accordance with{' '}
            <Link href="#" underline="hover">
              <Typography component="span" variant="body1">
                Sincere Travels&#39; Privacy Policy
              </Typography>
            </Link>
            .
          </StyledListItem>
          <StyledListItem>
            I accept{' '}
            <Link href="#" underline="hover">
              <Typography component="span" variant="body1">
                terms and condition of Airlines Name
              </Typography>
            </Link>
            .
          </StyledListItem>
        </StyledList>
      </Box>

      <Link href="/success">
        <Button variant="contained" size="large" fullWidth={!lgMatches}>
          Make Payment
        </Button>
      </Link>
    </>
  );
};

export default PaymentDetail;
