import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import CardActionArea from '@mui/material/CardActionArea';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

export const ExpandMoreIcon = styled('span')(({ theme }) => ({
  marginLeft: '4px',
}));

export const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <Button {...other} />;
})(
  ({ theme, expand }) => `
  text-transform: capitalize;
  font-weight: 700;
  display: flex;

  ${ExpandMoreIcon} {
  transform: ${!expand ? 'rotate(0deg)' : 'rotate(180deg)'};
  transition: ${theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  })};
}
`
);

export const BookPriceButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  boxShadow: 'none',
  width: '100%',
  justifyContent: 'space-between',
  padding: '14px 20px',

  '&:hover': {
    boxShadow: 'none',
  },
}));

// Typography
export const Tag = styled('h5')(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: '700',
  fontSize: '0.75rem',
  letterSpacing: '0.06em',
  color: theme.palette.success.main,
  lineHeight: '1.4',
}));

export const CardTextLarge = styled('h3')(({ theme }) => ({
  fontWeight: '700',
  fontSize: '1.25rem',
  lineHeight: '1.4',
  color: theme.palette.common.black,

  [theme.breakpoints.down('md')]: {
    fontSize: '1.125rem',
  },
}));

export const CardTextSmall = styled('p')(({ theme }) => ({
  fontWeight: '400',
  fontSize: '0.875rem',
  lineHeight: '1.2',
  color: theme.palette.neutral.gray,
}));

export const FlightDuration = styled(Typography)(({ theme }) => ({
  fontWeight: '400',
  fontSize: '1rem',
  lineHeight: '1.4',
  color: theme.palette.neutral.darkgray,
  whiteSpace: 'nowrap',

  [theme.breakpoints.down('md')]: {
    fontSize: '0.75rem',
  },
}));

// Image
export const AirlinesLogo = styled((props) => {
  return <img alt="" {...props} />;
})(({ theme }) => ({
  width: '45px',
  height: '45px',

  [theme.breakpoints.down('md')]: {
    width: '28px',
    height: '28px',
  },
}));

// Separator
export const Line = styled('span')(({ theme }) => ({
  // width: '100%',
  flexGrow: 1,
  borderTop: `1px solid ${theme.palette.neutral.placeholder}`,
  marginLeft: '0.75rem',
  marginRight: '0.75rem',

  [theme.breakpoints.down('md')]: {
    marginLeft: '0',
    marginRight: '0',
    borderTopWidth: 2,
  },
}));

export const Dot = styled('div')(
  ({ theme, size, mx, bg }) => `
  height: ${size || '4px'};
  width: ${size || '4px'};
  margin-left: ${mx || '8px'};
  margin-right: ${mx || '8px'};
  background-color: ${bg || theme.palette.common.black};
  border-radius: 50%;
`
);

// Layout
export const FlightSummaryContainer = styled(Paper)(({ theme }) => ({
  borderRadius: 0,

  '.st-flight-combination & + &:nth-of-type(n + 2)': {
    borderTop: `3px solid ${theme.palette.neutral.stroke}`,
  },
}));

export const FlexBox = styled(Box)`
  display: flex;
  flex-direction: ${({ fd }) => fd || 'row'};
  gap: ${({ gap }) => gap || '0'};
  align-items: ${({ items }) => items || 'flex-start'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
`;

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,

    '&::before': {
      boxShadow: theme.shadows[2],
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.neutral.darkgray,
    boxShadow: theme.shadows[2],
    fontSize: 14,
    lineHeight: 1.4,
    fontWeight: 400,
    padding: '13px 16px',
    borderRadius: 4,
    marginBottom: 4,
  },
  '&.MuiTooltip-tooltipPlacementTop': {},
}));

export const StyledCardActionArea = styled(CardActionArea)(() => ({
  cursor: "auto",
  "& .MuiCardActionArea-focusHighlight": { opacity: "0 !important" },
}));
