import { styled } from "@mui/material/styles";
import Dialog, { dialogClasses } from "@mui/material/Dialog";

export const BottomDialog = styled(Dialog)(({ theme }) => {
  return {
    [`& .${dialogClasses.paper}`]: {
      margin: 0,
      width: "100%",
      position: "absolute",
      bottom: 0,
      left: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  };
});
