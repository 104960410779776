const SuitcaseLarge = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 5.83335H13.3333V3.33335H14.1667V1.66669H5.83333V3.33335H6.66667V5.83335H4.16667C3.25 5.83335 2.5 6.58335 2.5 7.50002V15.8334C2.5 16.75 3.25 17.5 4.16667 17.5H5V18.3334H6.66667V17.5H13.3333V18.3334H15V17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8334V7.50002C17.5 6.58335 16.75 5.83335 15.8333 5.83335ZM8.33333 3.33335H11.6667V5.83335H8.33333V3.33335ZM15.8333 15.8334H4.16667V7.50002H15.8333V15.8334Z"
        fill="#484848"
      />
      <path
        d="M10.8334 9.16669H9.16675V14.1667H10.8334V9.16669Z"
        fill="#484848"
      />
      <path
        d="M7.49992 9.16669H5.83325V14.1667H7.49992V9.16669Z"
        fill="#484848"
      />
      <path d="M14.1667 9.16669H12.5V14.1667H14.1667V9.16669Z" fill="#484848" />
    </svg>
  );
};

export default SuitcaseLarge;
