import { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useTheme } from '@mui/material/styles';

import { BiPhone, BiEnvelope, BiSupport } from 'react-icons/bi';

const ContactMenu = ({ isLandingPage }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { palette } = useTheme();

  return (
    <>
      <Button
        onClick={handleClick}
        sx={{
          mr: 3,
          ...(isLandingPage
            ? {
                color: 'common.white',
              }
            : {
                color: 'primary.dark',
              }),
          '& .MuiButton-startIcon': {
            mr: 1,
          },
        }}
        startIcon={
          <BiSupport
            size="20px"
            color={isLandingPage ? palette.common.white : palette.primary.dark}
          />
        }
      >
        Contact Support
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem>
          <ListItemIcon>
            <BiPhone size="20px" />
          </ListItemIcon>
          +977 1 4357633
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <BiEnvelope size="20px" />
          </ListItemIcon>
          info@sinceretravels.com
        </MenuItem>
      </Menu>
    </>
  );
};

export default ContactMenu;
