import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const DialogImage = styled((props) => {
  return <img alt="" {...props} />;
})(({ theme }) => ({
  width: 'auto',
  maxHeight: '190px',
  objectFit: 'contain',

  // [theme.breakpoints.down('md')]: {
  //   width: '28px',
  //   height: '28px',
  // },
}));

export const ButtonSemiLarge = styled(Button)(({ theme }) => ({
  boxShadow: 'none',
  paddingTop: 12,
  paddingBottom: 12,
  '&:hover': {
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
  },
  '&:focus': {},
}));
