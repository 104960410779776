import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    "& .MuiToggleButtonGroup-grouped": {
      marginRight: theme.spacing(0.5),
      border: 0,
    },
    "& .MuiToggleButton-root": {
      borderRadius: 4,
    },
    "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
      borderRadius: 4,
    },
  })
);

export const StyledButton = styled((props) => (
  <Button variant="contained" fullWidth {...props} />
))(({ theme }) => ({
  height: "100%",
}));
