import { styled } from "@mui/material/styles";
import { InputBase } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "&:focus": {
    boxShadow: "unset",
  },
}));

export const StyledInputMobile = styled(OutlinedInput)(({ theme }) => {
  return {
    "&.MuiOutlinedInput-root": {
      padding: "0 0 0 4px",

      fieldset: {
        borderWidth: 0,
        borderBottomWidth: 2,
        borderRadius: 0,
        borderColor: theme.palette.neutral.stroke,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.neutral.stroke,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 0,
        borderBottomWidth: 2,
        boxShadow: "none",
        borderColor: theme.palette.neutral.stroke,
      },

      "& .MuiOutlinedInput-input": {
        position: "relative",
        borderRadius: 4,
        borderWidth: 2,
        fontSize: 16,
        lineHeight: "150%",
        width: "100%",
        padding: "16px 14px 16px 4px",
        boxShadow: "none",
      },
    },
  };
});
