import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 20px',
  height: '60px',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.neutral.stroke}`,
}));
