import { Component } from "react";

export class ErrorBoundary extends Component {
  constructor() {
    super();
    this.state = { hasError: false };
  }

  componentDidCatch(error) {
    console.log(error);
    this.setState({ hasError: true });
  }

  render() {
    return this.state.hasError ? (
      // TODO: Proper styling of page @roshit
      <p>
        Something went wrong! You can try to reach out to Ottr to resolve this
        matter quicker.
      </p>
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
