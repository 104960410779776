const Ticket = ({ ...others }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...others}
  >
    <g clipPath="url(#clip0_686_12941)">
      <path
        d="M7.75738 21.8993L6.62601 20.7679C6.13103 20.273 6.06032 19.4952 6.41387 18.8588C6.76743 18.5052 6.69672 18.0102 6.34316 17.6567C5.98961 17.3031 5.49464 17.2324 5.07037 17.5153C4.50469 17.9395 3.72687 17.8688 3.23189 17.3738L2.10052 16.2425C0.898441 15.0404 0.898441 13.2019 2.10052 11.9998L12 2.10032C13.2021 0.898243 15.1113 0.968953 16.3134 2.17103L17.3033 3.16098C17.869 3.72667 17.9397 4.50449 17.5155 5.07017C17.2326 5.49444 17.3033 5.98941 17.6569 6.34296C18.0104 6.69652 18.5054 6.76723 18.9297 6.48439C19.4953 6.06012 20.2732 6.13083 20.7681 6.62581L21.8995 7.75718C23.1016 8.95926 23.1016 10.7977 21.8995 11.9998L12 21.8993C10.7979 23.1014 8.95946 23.1014 7.75738 21.8993ZM8.32306 19.6366L9.17159 20.4851C9.59585 20.9094 10.1615 20.9094 10.5858 20.4851L20.4853 10.5856C20.9096 10.1613 20.9096 9.59566 20.4853 9.17139L19.6368 8.32286C18.5054 8.88855 17.1619 8.67642 16.2427 7.75718C15.3234 6.83794 15.1113 5.49444 15.677 4.36307L14.9699 3.65596C14.4749 3.16098 13.8385 3.09027 13.4142 3.51454L3.51474 13.414C3.09047 13.8383 3.09047 14.404 3.51474 14.8282L4.36326 15.6768C5.49464 15.1111 6.83814 15.3232 7.75738 16.2425C8.67662 17.1617 8.88875 18.5052 8.32306 19.6366Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_686_12941">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Ticket;
