import { useState } from "react";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import { StyledInput } from "./AuthDialogs.elements";
import { FlexBox } from "../_shared";

const SignUpForm = ({ onClose }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const handleRegister = () => {
    onClose();
  };

  return (
    <>
      <FlexBox fd="column" sx={{ width: "100%" }} gap="16px" mb={2.5}>
        <FlexBox gap="8px" sx={{ width: "100%" }}>
          <FormControl fullWidth>
            <StyledInput id="first_name" />
            <InputLabel htmlFor="first_name">First Name</InputLabel>
          </FormControl>

          <FormControl fullWidth>
            <StyledInput id="last_name" />
            <InputLabel htmlFor="last_name">Last Name</InputLabel>
          </FormControl>
        </FlexBox>

        <FormControl fullWidth>
          <StyledInput id="email" />
          <InputLabel htmlFor="email" type="email">
            Email
          </InputLabel>
        </FormControl>

        <FormControl fullWidth>
          <StyledInput
            id="password"
            type={showPassword ? "text" : "password"}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onClick={toggleShowPassword}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      textDecorationLine: "underline",
                      textUnderlineOffset: 3,
                      fontSize: 14,
                      lineHeight: 1.4,
                      cursor: "pointer",
                    }}
                  >
                    {showPassword ? "Hide" : "Show"}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
          <InputLabel htmlFor="password">Password</InputLabel>
        </FormControl>
      </FlexBox>

      <Button
        variant="contained"
        fullWidth
        sx={{ py: 1.625, mb: 4 }}
        onClick={handleRegister}
      >
        Register
      </Button>
    </>
  );
};

export default SignUpForm;
