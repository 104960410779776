import { useState, forwardRef } from "react";
import MUIAutocomplete, {
  createFilterOptions,
} from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { BiMap, BiArrowBack } from "react-icons/bi";

import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

import { StyledAutoCompleteInput } from "../StyledInput.styled";
import { StyledInputMobile } from "./Autocomplete.styled";
import PlaneIcon from "../../../assets/icons/Plane.icon";

import Slide from "@mui/material/Slide";

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Autocomplete = forwardRef(
  (
    {
      id,
      options,
      placeholder,
      value = null,
      onChange,
      groupBy = (option) => option.city,
      getOptionLabel = (option) => option.name,
      isOptionEqualToValue = (option, value) => option.code === value.code,
      filterOptions = createFilterOptions({
        stringify: (option) => option.name + option.city + option.code,
      }),
      startAdornment,
    },
    ref
  ) => {
    const { palette, breakpoints } = useTheme();

    const smUp = useMediaQuery(breakpoints.up("sm"));

    const [openSelectDialog, setOpenSelectDialog] = useState(false);

    const handleOpenSelectDialog = (e) => {
      e.target.blur();
      setOpenSelectDialog(true);
    };
    const handleCloseSelectDialog = () => setOpenSelectDialog(false);

    const handleMenuSelect = (value) => (e) => {
      onChange(e, value);
      handleCloseSelectDialog();
    };

    return (
      <>
        {smUp ? (
          <MUIAutocomplete
            ref={ref || null}
            id={id}
            options={options}
            groupBy={groupBy}
            getOptionLabel={getOptionLabel}
            value={value}
            onChange={onChange}
            fullWidth
            disableClearable
            forcePopupIcon={false}
            openOnFocus
            PopperComponent={(props) => (
              <Popper
                {...props}
                modifiers={[{ name: "offset", options: { offset: [-32, 0] } }]}
              />
            )}
            PaperComponent={({ children }) => (
              <Paper sx={{ minWidth: 448 }} elevation={2}>
                {children}
              </Paper>
            )}
            renderInput={({ InputProps, inputProps }) => (
              <StyledAutoCompleteInput
                inputRef={InputProps.ref}
                inputProps={inputProps}
                startAdornment={startAdornment}
                placeholder={placeholder}
                fullWidth
              />
            )}
            renderGroup={({ group, children }) => {
              return (
                <Box key={group}>
                  <Stack
                    direction="row"
                    spacing={1.5}
                    sx={{ mt: 0.5 }}
                    px={1.5}
                    py={1.75}
                  >
                    <BiMap />
                    <Typography>{group}</Typography>
                  </Stack>
                  {children}
                </Box>
              );
            }}
            renderOption={(props, option, { inputValue }) => {
              const matches = match(option.name, inputValue);
              const parts = parse(option.name, matches);
              return (
                <Stack
                  direction="row"
                  key={option.code}
                  spacing={1.5}
                  {...props}
                >
                  <PlaneIcon />
                  <>
                    <Typography
                      sx={{
                        color: palette.neutral.darkgray,
                        lineHeight: 1.5,
                        mr: 1,
                      }}
                    >
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </Typography>
                    <Typography
                      sx={{
                        color: palette.neutral.gray,
                        lineHeight: 1.5,
                      }}
                    >
                      {option.code}
                    </Typography>
                  </>
                </Stack>
              );
            }}
            isOptionEqualToValue={isOptionEqualToValue}
            filterOptions={filterOptions}
          />
        ) : (
          <>
            <StyledAutoCompleteInput
              value={value?.name || ""}
              startAdornment={startAdornment}
              placeholder={placeholder}
              fullWidth
              onClick={handleOpenSelectDialog}
            />

            <Dialog
              fullScreen
              open={openSelectDialog}
              onClose={handleCloseSelectDialog}
              TransitionComponent={Transition}
            >
              <MUIAutocomplete
                id={id}
                options={options}
                groupBy={groupBy}
                getOptionLabel={getOptionLabel}
                value={value}
                onChange={onChange}
                fullWidth
                disableClearable
                forcePopupIcon={false}
                open
                sx={{
                  width: "100%",
                  background: "white",
                  position: "sticky",
                  top: 0,
                  zIndex: 99999,
                }}
                ListboxProps={{ style: { maxHeight: "100%" } }}
                PaperComponent={({ children }) => (
                  <Paper elevation={0}>{children}</Paper>
                )}
                PopperComponent={({ style, ...props }) => {
                  return <Box {...props} sx={{ width: "100%" }} />;
                }}
                renderInput={({ InputProps, inputProps }) => {
                  return (
                    <StyledInputMobile
                      inputRef={InputProps.ref}
                      inputProps={inputProps}
                      startAdornment={
                        <IconButton
                          color="inherit"
                          onClick={handleCloseSelectDialog}
                          aria-label="close"
                        >
                          <BiArrowBack />
                        </IconButton>
                      }
                      placeholder={placeholder}
                      fullWidth
                      autoFocus
                    />
                  );
                }}
                renderGroup={({ group, children }) => {
                  return (
                    <Box key={group}>
                      <Stack
                        direction="row"
                        spacing={1.5}
                        sx={{ mt: 0.5 }}
                        px={1.5}
                        py={1.75}
                      >
                        <BiMap />
                        <Typography>{group}</Typography>
                      </Stack>
                      {children}
                    </Box>
                  );
                }}
                renderOption={(props, option, { inputValue }) => {
                  const matches = match(option.name, inputValue);
                  const parts = parse(option.name, matches);

                  return (
                    <Stack
                      direction="row"
                      key={option.code}
                      spacing={1.5}
                      {...props}
                      onClick={handleMenuSelect(option)}
                    >
                      <PlaneIcon />
                      <>
                        <Typography
                          sx={{
                            color: palette.neutral.darkgray,
                            lineHeight: 1.5,
                            mr: 1,
                          }}
                        >
                          {parts.map((part, index) => (
                            <span
                              key={index}
                              style={{
                                fontWeight: part.highlight ? 700 : 400,
                              }}
                            >
                              {part.text}
                            </span>
                          ))}
                        </Typography>
                        <Typography
                          sx={{
                            color: palette.neutral.gray,
                            lineHeight: 1.5,
                          }}
                        >
                          {option.code}
                        </Typography>
                      </>
                    </Stack>
                  );
                }}
                isOptionEqualToValue={isOptionEqualToValue}
                filterOptions={filterOptions}
              />
            </Dialog>
          </>
        )}
      </>
    );
  }
);
