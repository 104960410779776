import { useState } from 'react';
import {
  // Components
  ExpandMore,
  ExpandMoreIcon,
  AirlinesLogo,

  // Typography
  CardTextLarge,
  CardTextSmall,
  FlightDuration,

  // Layout
  FlightSummaryContainer,

  // Separator
  Line,
  Dot,
} from './FlightCard.elements';
import {
  // Component
  ItineraryAirlinesLogo,

  // Typography
  ItineraryText,
  ItineraryTextSmall,
  ItineraryTextShrink,

  // Separator
  TimelineLine,
  LayoverDots,

  // Layouts
  IniItineraryGrid,
  FinItineraryGrid,
  TimelineContainer,
  AirLogo,
  DepLocation,
  DepDuration,
  AriLocation,
  FlightDetail,
  LayoverDotsContainer,
  LayoverContainer,
} from './Itinerary.elements';
import { FlexBox } from '../_shared/Flex.styled';
import Stack from '@mui/material/Stack';

import Typography from '@mui/material/Typography';

import XCircle from '../../assets/icons/XCircle.icon';
import Meal from '../../assets/icons/Meal.icon';
import PlaneDown from '../../assets/icons/PlaneDown.icon';
import Origin from '../../assets/icons/Origin.icon';
import LocationPin from '../../assets/icons/LocationPin.icon';
import SuitcaseLarge from '../../assets/icons/SuitcaseLarge.icon';
import Baggage from '../../assets/icons/Baggage.icon';
import { BiCalendarAlt } from 'react-icons/bi';

import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { StyledTableCell } from '../_shared/Table.styled';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import ArrowHead from '../../assets/icons/ArrowHead.icon';
import StopLine from '../../assets/icons/StopLine.icon';

import { TabPanel, a11yProps } from '../';

function createData(
  paxType,
  penalty,
  applicability,
  availability,
  applicableCharges
) {
  return { paxType, penalty, applicability, availability, applicableCharges };
}

const rows = [
  createData('ADT', 'Exchange', 'Before Flight', 'Yes', 'NPR 6150.0/person'),
  createData('ADT', 'Exchange', 'After Flight', 'Yes', 'NPR 6150.0/person'),
  createData('ADT', 'Refund', 'Before Flight', 'Yes', 'NPR 6150.0/person'),
  createData('ADT', 'Refund', 'After Flight', 'Yes', 'NPR 6150.0/person'),
];

const FlightCardSummary = ({ detail }) => {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const { palette } = useTheme();
  const theme = useTheme();
  const mdMatches = useMediaQuery(theme.breakpoints.up('md'));

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <FlightSummaryContainer>
      <Box p={2.5}>
        {/* ------------- Date info goes here ------------- */}
        {!mdMatches && (
          <Box mb={1.5}>
            <Stack direction={'row'} spacing="8px" alignItems={'center'}>
              <BiCalendarAlt size={20} color={palette.neutral.darkgray} />
              <Typography
                variant="body2"
                fontWeight={'medium'}
                color="neutral.darkgray"
              >
                {detail.date}
              </Typography>
            </Stack>
          </Box>
        )}

        {/* mid summary */}
        <Grid mb={3} container>
          <Grid item xs={12} md={9}>
            <Stack direction="row">
              {/* logo */}
              <Box mr={2}>
                <AirlinesLogo src="/airlinesLogo.png" alt="airlines name" />
              </Box>

              {/* time and airports summary */}
              <Stack spacing={1} flex={1}>
                {mdMatches && (
                  <Stack spacing={1}>
                    <FlexBox items="center" sx={{ flex: '1', width: '100%' }}>
                      <CardTextLarge>{detail.date}</CardTextLarge>
                      <Dot size="6px" mx="12px" />
                      <CardTextLarge>3:15 PM &mdash; 6:45 AM</CardTextLarge>
                    </FlexBox>
                  </Stack>
                )}

                {/* Info for mobile */}

                {!mdMatches && (
                  <Stack spacing={2} direction="row" flex={1}>
                    <Stack spacing={0.5}>
                      <CardTextLarge>3:15 PM</CardTextLarge>
                      <CardTextSmall>KTM</CardTextSmall>
                    </Stack>
                    <Stack mx={2} spacing={0.4} flex={1}>
                      <FlightDuration align="center">20hr 15min</FlightDuration>

                      {/* Arrow */}
                      <Stack direction={'row'} alignItems={'center'}>
                        <Line />
                        {
                          // true if there is one stop
                          true && (
                            <>
                              <StopLine />
                              <Line />
                              {
                                // true if there are two stops
                                false && (
                                  <>
                                    <StopLine />
                                    <Line />
                                  </>
                                )
                              }
                            </>
                          )
                        }
                        <ArrowHead />
                      </Stack>

                      <FlightDuration align="center">1 Stop</FlightDuration>
                    </Stack>
                    <Stack spacing={0.5}>
                      <CardTextLarge>6:45 AM</CardTextLarge>
                      <CardTextSmall>LHR</CardTextSmall>
                    </Stack>
                  </Stack>
                )}

                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    display: {
                      xs: 'none',
                      md: 'flex',
                    },
                  }}
                >
                  <Box sx={{ maxWidth: '45%' }}>
                    <CardTextSmall>
                      Kathmandu Tribhuwan Intl. (KTM)
                    </CardTextSmall>
                  </Box>
                  <CardTextSmall>&mdash;</CardTextSmall>
                  <Box sx={{ maxWidth: '45%' }}>
                    <CardTextSmall>London Heathrow Airport (LHR)</CardTextSmall>
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Grid>

          {/* Details show hide */}
          {mdMatches && (
            <Grid
              item
              md={3}
              sx={{
                alignSelf: 'center',
              }}
            >
              <FlexBox justify="flex-end">
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="Show detail"
                  variant="text"
                >
                  Flight Details
                  <ExpandMoreIcon>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.862 6.19531L7.99998 9.05731L5.13798 6.19531L4.19531 7.13798L7.99998 10.9426L11.8046 7.13798L10.862 6.19531Z"
                        fill="#1D76E2"
                      />
                    </svg>
                  </ExpandMoreIcon>
                </ExpandMore>
              </FlexBox>
            </Grid>
          )}
        </Grid>

        {/* bot footer */}
        <FlexBox justify="space-between" items="center">
          <FlexBox gap="24px" items="center">
            <FlexBox gap="8px" items="center">
              <XCircle />
              <CardTextSmall>Non-Refundable</CardTextSmall>
            </FlexBox>

            {mdMatches && (
              <FlexBox gap="8px" items="center">
                <Meal />
                <CardTextSmall>Free Meal</CardTextSmall>
              </FlexBox>
            )}
          </FlexBox>

          {mdMatches && (
            <FlexBox items="center">
              <CardTextSmall>20hr 15min</CardTextSmall>
              <Dot />
              <CardTextSmall>1 Stop</CardTextSmall>
            </FlexBox>
          )}

          {!mdMatches && (
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="Show detail"
              variant="text"
            >
              Flight Details
              <ExpandMoreIcon>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.862 6.19531L7.99998 9.05731L5.13798 6.19531L4.19531 7.13798L7.99998 10.9426L11.8046 7.13798L10.862 6.19531Z"
                    fill="#1D76E2"
                  />
                </svg>
              </ExpandMoreIcon>
            </ExpandMore>
          )}
        </FlexBox>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              scrollButtons="auto"
              allowScrollButtonsMobile
              variant="scrollable"
              aria-label="basic tabs example"
              sx={{ px: { xs: 0, sm: 2.5 } }}
            >
              <Tab label="Itinerary" {...a11yProps(0)} />
              <Tab label="Penalties info" {...a11yProps(1)} />
              <Tab label="Baggage Details" {...a11yProps(2)} />
              <Tab label="Fare Rule" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Box py={2.5} pl={2.5}>
              {/* ---------- FLight Before last ---------- */}
              <IniItineraryGrid>
                {/* Airlines logo */}
                <AirLogo>
                  <ItineraryAirlinesLogo
                    src="/airlinesLogo.png"
                    alt="airlines name"
                  />
                </AirLogo>

                {/* Departure Time & Airport */}
                <DepLocation>
                  {/* Time */}
                  <ItineraryText>3:15 PM</ItineraryText>

                  <Dot sx={{ display: { xs: 'none', md: 'block' } }} />

                  {/* Airport */}
                  <ItineraryTextShrink>
                    Tribhuwan International Airport (KTM)
                  </ItineraryTextShrink>
                </DepLocation>

                {/* Departure Duration */}
                <DepDuration>
                  <ItineraryTextSmall>4hr 15min</ItineraryTextSmall>
                </DepDuration>

                {/* Arrival Time & Airport */}
                <AriLocation>
                  {/* Time */}
                  <ItineraryText>6:20 PM</ItineraryText>

                  <Dot sx={{ display: { xs: 'none', md: 'block' } }} />

                  {/* Airport */}
                  <ItineraryTextShrink>
                    Abu Dhabi International Airport (AUH)
                  </ItineraryTextShrink>
                </AriLocation>

                {/* Flight Details */}
                <FlightDetail>
                  {/* Airlines */}
                  <ItineraryTextSmall>Quatar Airways</ItineraryTextSmall>
                  <Dot size="3px" bg={palette.neutral.gray} />
                  {/* Class */}
                  <ItineraryTextSmall>Economy</ItineraryTextSmall>
                  <Dot size="3px" bg={palette.neutral.gray} />
                  {/* Airplane Name */}
                  <ItineraryTextSmall>3L 46</ItineraryTextSmall>
                </FlightDetail>

                {/* ---------- Layover Details ---------- */}
                <LayoverContainer>
                  {/* Duration */}
                  <ItineraryTextSmall>7hr 55min layover</ItineraryTextSmall>
                  <Dot size="3px" bg={palette.neutral.gray} />

                  {/* Location */}
                  <ItineraryTextSmall>Abu Dhabi (AUH)</ItineraryTextSmall>
                </LayoverContainer>

                <TimelineContainer>
                  <Box mt={0.125}>
                    <PlaneDown />
                  </Box>
                  <TimelineLine />
                  <Box mb={{ xs: 2.625, md: 0.125 }}>
                    <Origin />
                  </Box>
                </TimelineContainer>

                <LayoverDotsContainer>
                  <LayoverDots />
                </LayoverDotsContainer>
              </IniItineraryGrid>

              {/* ---------- Last Flight ---------- */}
              <FinItineraryGrid>
                {/* Airlines logo */}
                <AirLogo>
                  <ItineraryAirlinesLogo
                    src="/airlinesLogo.png"
                    alt="airlines name"
                  />
                </AirLogo>

                {/* Departure Time & Airport */}
                <DepLocation>
                  {/* Time */}
                  <ItineraryText>2:15 AM</ItineraryText>
                  <Dot sx={{ display: { xs: 'none', md: 'block' } }} />

                  {/* Airport */}
                  <ItineraryTextShrink>
                    Abu Dhabi International Airport (AUH)
                  </ItineraryTextShrink>
                </DepLocation>

                {/* Departure Duration */}
                <DepDuration>
                  <ItineraryTextSmall>7hr 30min</ItineraryTextSmall>
                </DepDuration>

                {/* Arrival Time & Airport */}
                <AriLocation>
                  {/* Time */}
                  <ItineraryText>6:45 AM</ItineraryText>
                  <Dot sx={{ display: { xs: 'none', md: 'block' } }} />

                  {/* Airport */}
                  <ItineraryTextShrink>
                    London Heathrow Airport (LHR)
                  </ItineraryTextShrink>
                </AriLocation>

                {/* Flight Details */}
                <FlightDetail>
                  {/* Airlines */}
                  <ItineraryTextSmall>Etihad</ItineraryTextSmall>
                  <Dot size="3px" bg={palette.neutral.gray} />
                  {/* Class */}
                  <ItineraryTextSmall>Economy</ItineraryTextSmall>
                  <Dot size="3px" bg={palette.neutral.gray} />
                  {/* Airplane Name */}
                  <ItineraryTextSmall>EY 11</ItineraryTextSmall>
                </FlightDetail>

                <TimelineContainer>
                  <Box mt={0.125}>
                    <PlaneDown />
                  </Box>
                  <TimelineLine />
                  <Box mb={{ xs: 2.625, md: 0.125 }}>
                    <LocationPin />
                  </Box>
                </TimelineContainer>
              </FinItineraryGrid>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box p={2.5}>
              <TableContainer component={Paper} elevation={0}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Pax Type</StyledTableCell>
                      <StyledTableCell>Penalty</StyledTableCell>
                      <StyledTableCell>Applicability</StyledTableCell>
                      <StyledTableCell>Availability</StyledTableCell>
                      <StyledTableCell align="right">
                        Applicable Charges
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      '&.MuiTableBody-root:before': {
                        content: '"@"',
                        display: 'block',
                        lineHeight: '16px',
                        textIndent: '-99999px',
                      },
                    }}
                  >
                    {rows.map((row, index) => (
                      <TableRow
                        key={index}
                        hover={true}
                        sx={{
                          cursor: 'pointer',
                        }}
                      >
                        <StyledTableCell>{row.paxType}</StyledTableCell>
                        <StyledTableCell>{row.penalty}</StyledTableCell>
                        <StyledTableCell>{row.applicability}</StyledTableCell>
                        <StyledTableCell>{row.availability}</StyledTableCell>
                        <StyledTableCell align="right">
                          {row.applicableCharges}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Grid container spacing={2} p={2.5}>
              <Grid item xs={6}>
                <FlexBox gap="12px">
                  <Box
                    p={0.6}
                    sx={{
                      border: 1,
                      borderColor: 'neutral.stroke',
                      borderRadius: 1.5,
                    }}
                  >
                    <SuitcaseLarge />
                  </Box>
                  <FlexBox fd="column">
                    <Typography
                      component="h4"
                      variant="body2"
                      sx={{ fontWeight: 'medium' }}
                    >
                      Allowance
                    </Typography>

                    <Typography paragraph variant="body1">
                      20kg / person
                    </Typography>
                  </FlexBox>
                </FlexBox>
              </Grid>

              <Grid item xs={6}>
                <FlexBox gap="12px">
                  <Box
                    p={0.6}
                    sx={{
                      border: 1,
                      borderColor: 'neutral.stroke',
                      borderRadius: 1.5,
                    }}
                  >
                    <Baggage />
                  </Box>
                  <FlexBox fd="column">
                    <Typography
                      component="h4"
                      variant="body2"
                      sx={{ fontWeight: 'medium' }}
                    >
                      Hand Carriage
                    </Typography>

                    <Typography paragraph variant="body1">
                      7kg / person
                    </Typography>
                  </FlexBox>
                </FlexBox>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Box p={2.5}>Add fare rules here</Box>
          </TabPanel>
        </Box>
      </Collapse>
    </FlightSummaryContainer>
  );
};

export default FlightCardSummary;
