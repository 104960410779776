const XCircle = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 1.66663C5.40508 1.66663 1.66675 5.40496 1.66675 9.99996C1.66675 14.595 5.40508 18.3333 10.0001 18.3333C14.5951 18.3333 18.3334 14.595 18.3334 9.99996C18.3334 5.40496 14.5951 1.66663 10.0001 1.66663ZM13.5059 12.3275L12.3276 13.5058L10.0001 11.1783L7.67258 13.5058L6.49425 12.3275L8.82175 9.99996L6.49425 7.67246L7.67258 6.49413L10.0001 8.82163L12.3276 6.49413L13.5059 7.67246L11.1784 9.99996L13.5059 12.3275Z"
        fill="#D33E43"
      />
    </svg>
  );
};

export default XCircle;
