const TicketContained = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.15" width="32" height="32" rx="4" fill="#1D76E2" />
      <path
        d="M12.0806 25.8993L10.9021 24.7679C10.3865 24.273 10.3128 23.4952 10.6811 22.8588C11.0494 22.5052 10.9757 22.0102 10.6075 21.6567C10.2392 21.3031 9.72357 21.2324 9.28163 21.5153C8.69237 21.9395 7.88214 21.8688 7.36655 21.3738L6.18803 20.2425C4.93587 19.0404 4.93587 17.2019 6.18803 15.9998L16.5 6.10032C17.7522 4.89824 19.7409 4.96895 20.9931 6.17103L22.0243 7.16098C22.6135 7.72667 22.6872 8.50449 22.2453 9.07017C21.9506 9.49444 22.0243 9.98941 22.3926 10.343C22.7608 10.6965 23.2764 10.7672 23.7184 10.4844C24.3076 10.0601 25.1179 10.1308 25.6335 10.6258L26.812 11.7572C28.0642 12.9593 28.0642 14.7977 26.812 15.9998L16.5 25.8993C15.2478 27.1014 13.3328 27.1014 12.0806 25.8993ZM12.6698 23.6366L13.5537 24.4851C13.9957 24.9094 14.5849 24.9094 15.0269 24.4851L25.3388 14.5856C25.7808 14.1613 25.7808 13.5957 25.3388 13.1714L24.455 12.3229C23.2764 12.8885 21.877 12.6764 20.9194 11.7572C19.9619 10.8379 19.7409 9.49444 20.3302 8.36307L19.5936 7.65596C19.078 7.16098 18.4151 7.09027 17.9731 7.51454L7.66117 17.414C7.21923 17.8383 7.21923 18.404 7.66117 18.8282L8.54506 19.6768C9.72357 19.1111 11.1231 19.3232 12.0806 20.2425C13.0381 21.1617 13.2591 22.5052 12.6698 23.6366Z"
        fill="#1D76E2"
      />
    </svg>
  );
};

export default TicketContained;
