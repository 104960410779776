import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import {
  CustomSwitch,
  DividerTitle,
  FlexBox,
  SincereCard,
  StyledInput,
  GroupStyledSelect,
  GroupStyledInput,
  CovidInfo,
} from "../../../components/_shared";
import { Button, Typography } from "@mui/material";

import Stack from "@mui/material/Stack";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const PassengerDetails = ({ onClick }) => {
  const { breakpoints } = useTheme();
  const lgMatches = useMediaQuery(breakpoints.up("lg"));

  const [nationality, setNationality] = useState("");
  const handleNationalityChange = (event) => {
    setNationality(event.target.value);
  };

  const [gender, setGender] = useState("");
  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const [dobMonth, setDobMonth] = useState("");
  const handleDobMonthChange = (event) => {
    setDobMonth(event.target.value);
  };

  const [doc, setDoc] = useState("");
  const handleDocChange = (event) => {
    setDoc(event.target.value);
  };

  const [expMonth, setExpMonth] = useState("");
  const handleExpMonthChange = (event) => {
    setExpMonth(event.target.value);
  };

  const [mealRequest, setMealRequest] = useState("");
  const handleMealRequestChange = (event) => {
    setMealRequest(event.target.value);
  };

  const [country, setCountry] = useState("Nepal");
  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  return (
    <>
      <CovidInfo />
      <DividerTitle>
        <Typography variant="subtitle1">Traveller’s Information</Typography>
      </DividerTitle>

      {/* Contact Details */}
      <SincereCard sx={{ mb: { xs: "16px", md: "24px" } }}>
        <SincereCard.Header>
          <Typography variant="h4" sx={{ fontWeight: "medium" }}>
            Contact Details
          </Typography>
          {/* <Button variant="text">Edit</Button> */}
        </SincereCard.Header>
        <SincereCard.Body>
          <Grid container columns={12} spacing={2.5}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <StyledInput id="email" type="email" />
                <InputLabel htmlFor="email">Email Address</InputLabel>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack>
                <Typography
                  variant="body1"
                  component="label"
                  mb={0.75}
                  sx={{ color: "neutral.darkgray" }}
                >
                  Phone Number
                </Typography>
                <FormGroup row>
                  <Select
                    labelId="country-select-label"
                    id="country"
                    value={country}
                    defaultValue={1}
                    onChange={handleCountryChange}
                    input={<GroupStyledSelect />}
                  >
                    <MenuItem value={"Nepal"}>+977</MenuItem>
                    <MenuItem value={"indian"}>indian</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem>
                  </Select>

                  <GroupStyledInput
                    sx={{ flexGrow: 1 }}
                    id="phoneNumber"
                    type="text"
                  />
                </FormGroup>
              </Stack>
            </Grid>
          </Grid>
        </SincereCard.Body>
      </SincereCard>

      {/* Passenger Details */}
      <SincereCard sx={{ mb: { xs: "24px", md: "32px" } }}>
        <SincereCard.Header>
          <Typography variant="h4" sx={{ fontWeight: "medium" }}>
            Main Passenger
          </Typography>
          <Typography variant="body2" sx={{ color: "neutral.gray" }}>
            Adult
          </Typography>
        </SincereCard.Header>
        <SincereCard.Body>
          <Grid container spacing={2.5}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <StyledInput id="fname" type="text" />
                <InputLabel htmlFor="fname">First Name</InputLabel>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <StyledInput id="lname" type="text" />
                <InputLabel htmlFor="lname">Last Name</InputLabel>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Select
                  labelId="nationality-select-label"
                  id="nationality"
                  value={nationality}
                  onChange={handleNationalityChange}
                  input={<StyledInput />}
                >
                  <MenuItem value={"Nepalese"}>Nepalese</MenuItem>
                  <MenuItem value={"indian"}>indian</MenuItem>
                  <MenuItem value={"Others"}>Others</MenuItem>
                </Select>
                <InputLabel htmlFor="nationality-select-label">
                  Nationality
                </InputLabel>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Select
                  labelId="gender-select-label"
                  id="gender"
                  value={gender}
                  onChange={handleGenderChange}
                  input={<StyledInput />}
                >
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value={"Female"}>Female</MenuItem>
                  <MenuItem value={"Others"}>Others</MenuItem>
                </Select>
                <InputLabel htmlFor="gender-select-label">Gender</InputLabel>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={10} md={6}>
              <FlexBox fd="column-reverse">
                <FlexBox
                  mt={0.875}
                  items="center"
                  gap="8px"
                  sx={{ width: "100%" }}
                >
                  <StyledInput
                    sx={{ width: "80px" }}
                    id="date"
                    type="text"
                    placeholder="DD"
                  />
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      display: { xs: "none", sm: "block" },
                      color: "neutral.gray",
                    }}
                  >
                    /
                  </Typography>
                  <Select
                    labelId="dobMonth-select-label"
                    id="dobMonth"
                    displayEmpty
                    value={dobMonth}
                    onChange={handleDobMonthChange}
                    input={<StyledInput />}
                    sx={{ flex: "1", width: "100%" }}
                    renderValue={(selected) => {
                      if (selected.length === 0)
                        return (
                          <Typography
                            variant="body1"
                            color="neutral.placeholder"
                          >
                            Month
                          </Typography>
                        );

                      return selected;
                    }}
                  >
                    <MenuItem value={"January"}>January</MenuItem>
                    <MenuItem value={"February"}>February</MenuItem>
                    <MenuItem value={"March"}>March</MenuItem>
                  </Select>
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      display: { xs: "none", sm: "block" },
                      color: "neutral.gray",
                    }}
                  >
                    /
                  </Typography>
                  <StyledInput
                    sx={{ width: "90px" }}
                    id="year"
                    type="text"
                    placeholder="YYYY"
                  />
                </FlexBox>
                <Typography
                  variant="body1"
                  component="label"
                  sx={{ color: "neutral.darkgray" }}
                >
                  Date of Birth
                </Typography>
              </FlexBox>
            </Grid>

            <Box
              component={Grid}
              item
              md={6}
              display={{ xs: "none", md: "block" }}
            />

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Select
                  labelId="doc-select-label"
                  id="doc"
                  value={doc}
                  onChange={handleDocChange}
                  input={<StyledInput />}
                >
                  <MenuItem value={"Passport"}>Passport</MenuItem>
                  <MenuItem value={"Citizenship"}>Citizenship</MenuItem>
                </Select>
                <InputLabel htmlFor="document-select-label">
                  Document
                </InputLabel>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <StyledInput id="docNumber" type="text" />
                <InputLabel htmlFor="docNumber">Document Number</InputLabel>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={10} md={6}>
              <FlexBox fd="column-reverse">
                <FlexBox
                  mt={0.875}
                  items="center"
                  gap="8px"
                  sx={{ width: "100%" }}
                >
                  <StyledInput
                    sx={{ width: "80px" }}
                    id="expDate"
                    type="text"
                    placeholder="DD"
                  />
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      display: { xs: "none", sm: "block" },
                      color: "neutral.gray",
                    }}
                  >
                    /
                  </Typography>
                  <Select
                    labelId="expMonth-select-label"
                    id="expMonth"
                    displayEmpty
                    value={expMonth}
                    onChange={handleExpMonthChange}
                    input={<StyledInput />}
                    sx={{ flex: "1", width: "100%" }}
                    renderValue={(selected) => {
                      if (selected.length === 0)
                        return (
                          <Typography
                            variant="body1"
                            color="neutral.placeholder"
                          >
                            Month
                          </Typography>
                        );

                      return selected;
                    }}
                  >
                    <MenuItem value={"January"}>January</MenuItem>
                    <MenuItem value={"February"}>February</MenuItem>
                    <MenuItem value={"March"}>March</MenuItem>
                    <MenuItem value={"September"}>September</MenuItem>
                  </Select>
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      display: { xs: "none", sm: "block" },
                      color: "neutral.gray",
                    }}
                  >
                    /
                  </Typography>
                  <StyledInput
                    sx={{ width: "90px" }}
                    id="expYear"
                    type="text"
                    placeholder="YYYY"
                  />
                </FlexBox>
                <Typography
                  variant="body1"
                  component="label"
                  sx={{ color: "neutral.darkgray" }}
                >
                  Document Expiry Date
                </Typography>
              </FlexBox>
            </Grid>

            <Box
              component={Grid}
              item
              md={6}
              display={{ xs: "none", md: "block" }}
            />

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Select
                  labelId="mealRequest-select-label"
                  id="mealRequest"
                  value={mealRequest}
                  onChange={handleMealRequestChange}
                  input={<StyledInput />}
                >
                  <MenuItem value={"Vegetarian"}>Vegetarian</MenuItem>
                  <MenuItem value={"Non-Vegetarian"}>Non-Vegetarian</MenuItem>
                  <MenuItem value={"Eggetarian"}>Eggetarian</MenuItem>
                </Select>
                <InputLabel htmlFor="mealRequest-select-label">
                  Meal Request <em>(Optional)</em>
                </InputLabel>
              </FormControl>
            </Grid>
          </Grid>
        </SincereCard.Body>

        <SincereCard.Footer>
          <FormControlLabel
            control={<CustomSwitch sx={{ m: 1 }} defaultChecked />}
            label="Save these details to your profile"
          />
        </SincereCard.Footer>
      </SincereCard>

      <Button
        variant="contained"
        size="large"
        onClick={onClick}
        fullWidth={!lgMatches}
      >
        Proceed to Payment
      </Button>
    </>
  );
};
export default PassengerDetails;
