import { Button, Menu, MenuItem } from "@mui/material";
import styled from "styled-components";

export const StyledImage = styled.img`
  height: 32px;
`;

export const StyledButton = styled(Button)(({ theme }) => ({
  boxShadow: "none",
  fontSize: 16,
  padding: "6px",
  lineHeight: 1.4,
  color: theme.palette.neutral.black,
  "&:hover": {
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
  },
  "&:focus": {},
}));

export const StyledMenu = styled((props) => (
  <Menu
    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    transformOrigin={{ vertical: "top", horizontal: "right" }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 280,
    boxShadow: "0px 4px 12px rgba(33, 33, 33, 0.08)",
    "& .MuiMenu-list": {
      padding: "0.5rem",
    },
    "&. MuiMenuItem-root": {
      borderRadius: 4,
      paddingLeft: 8,
      paddingRight: 8,
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

export const StyledMenuItem = styled((props) => <MenuItem {...props} />)(
  ({ theme }) => ({
    borderRadius: 4,
    padding: "14px 8px",
    marginLeft: 0,
    marginRight: 0,
  })
);
