const Google = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6713 8.36788H18.0001V8.33329H10.5001V11.6666H15.2097C14.5226 13.607 12.6763 15 10.5001 15C7.73883 15 5.50008 12.7612 5.50008 9.99996C5.50008 7.23871 7.73883 4.99996 10.5001 4.99996C11.7747 4.99996 12.9342 5.48079 13.8172 6.26621L16.1743 3.90913C14.6859 2.52204 12.6951 1.66663 10.5001 1.66663C5.898 1.66663 2.16675 5.39788 2.16675 9.99996C2.16675 14.602 5.898 18.3333 10.5001 18.3333C15.1022 18.3333 18.8334 14.602 18.8334 9.99996C18.8334 9.44121 18.7759 8.89579 18.6713 8.36788Z"
        fill="#FFC107"
      />
      <path
        d="M3.12744 6.12121L5.86536 8.12913C6.60619 6.29496 8.40036 4.99996 10.4999 4.99996C11.7745 4.99996 12.9341 5.48079 13.817 6.26621L16.1741 3.90913C14.6858 2.52204 12.6949 1.66663 10.4999 1.66663C7.29911 1.66663 4.52327 3.47371 3.12744 6.12121Z"
        fill="#FF3D00"
      />
      <path
        d="M10.5 18.3334C12.6525 18.3334 14.6084 17.5096 16.0871 16.17L13.508 13.9875C12.6713 14.6213 11.6313 15 10.5 15C8.33255 15 6.49213 13.618 5.7988 11.6892L3.0813 13.783C4.46047 16.4817 7.2613 18.3334 10.5 18.3334Z"
        fill="#4CAF50"
      />
      <path
        d="M18.6713 8.36796H18V8.33337H10.5V11.6667H15.2096C14.8796 12.5988 14.28 13.4025 13.5067 13.988C13.5071 13.9875 13.5075 13.9875 13.5079 13.9871L16.0871 16.1696C15.9046 16.3355 18.8333 14.1667 18.8333 10C18.8333 9.44129 18.7758 8.89587 18.6713 8.36796Z"
        fill="#1976D2"
      />
    </svg>
  );
};

export default Google;
