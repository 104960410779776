import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
  SincereCard,
  StyledInput,
  GroupStyledSelect,
  GroupStyledInput,
} from '../../../components/_shared';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { ButtonSemiLarge } from './Profile.styled';

import FormGroup from '@mui/material/FormGroup';

const EditContact = ({ onFinish, data }) => {
  const [country, setCountry] = useState('Nepal');
  const handleCountryChange = (event) => setCountry(event.target.value);

  return (
    <>
      <Typography variant="h2" fontWeight="medium" mb={4}>
        Edit Contact Details
      </Typography>
      <SincereCard sx={{ mb: { xs: '24px', md: '32px' } }}>
        <SincereCard.Header>
          <Typography variant="h4" sx={{ fontWeight: 'medium' }}>
            Contact Details
          </Typography>
        </SincereCard.Header>
        <SincereCard.Body>
          <Grid container columns={12} spacing={2.5}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <StyledInput
                  id="email"
                  type="email"
                  defaultValue={data.email}
                />
                <InputLabel htmlFor="email">Email Address</InputLabel>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack>
                <Typography
                  variant="body1"
                  component="label"
                  mb={0.75}
                  sx={{ color: 'neutral.darkgray' }}
                >
                  Phone Number
                </Typography>
                <FormGroup row>
                  <Select
                    labelId="country-select-label"
                    id="country"
                    value={country}
                    defaultValue={1}
                    onChange={handleCountryChange}
                    input={<GroupStyledSelect />}
                  >
                    <MenuItem value={'Nepal'}>+977</MenuItem>
                    <MenuItem value={'indian'}>indian</MenuItem>
                    <MenuItem value={'Others'}>Others</MenuItem>
                  </Select>

                  <GroupStyledInput
                    sx={{ flexGrow: 1 }}
                    id="phoneNumber"
                    type="text"
                    defaultValue={data.phone}
                  />
                </FormGroup>
              </Stack>
            </Grid>
          </Grid>
        </SincereCard.Body>
      </SincereCard>
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <ButtonSemiLarge variant="outlined" onClick={onFinish}>
          Cancel
        </ButtonSemiLarge>
        <ButtonSemiLarge variant="contained">Save Changes</ButtonSemiLarge>
      </Stack>
    </>
  );
};

export default EditContact;
