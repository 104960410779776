import React from 'react';

const StopLine = () => {
  return (
    <svg
      width="2"
      height="6"
      viewBox="0 0 2 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="2" height="6" fill="#AAAAAA" />
    </svg>
  );
};

export default StopLine;
