import { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import PassengerDetails from "./components/PassengerDetails";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import PaymentDetail from "./components/PaymentDetail";
import FlightDetail from "./components/FlightDetail";
import PriceSection from "./components/PriceSection";
import EntryTimer from "./components/EntryTimer";

const steps = ["Search", "Passenger Details", "Review & Pay"];

const PNRPage = () => {
  const { breakpoints } = useTheme();
  const lgMatches = useMediaQuery(breakpoints.up("lg"));
  const mdMatches = useMediaQuery(breakpoints.up("md"));

  const [activeStep, setActiveStep] = useState(1);

  const nextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStepClick = (step) => {
    if (step !== activeStep && step !== 0) {
      setActiveStep(step);
    }
  };

  return (
    <Container className="st-no-footer" fixed>
      <Grid my={6} container columnSpacing={4}>
        <Grid item xs={12} lg={8}>
          <Stepper activeStep={activeStep} alternativeLabel={!mdMatches}>
            {steps.map((label, index) => {
              return (
                <Step
                  key={label}
                  onClick={() => {
                    handleStepClick(index);
                  }}
                >
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} lg={8}>
          {!lgMatches && <EntryTimer />}

          <FlightDetail />

          {!lgMatches && <PriceSection />}

          {activeStep === 1 && <PassengerDetails onClick={nextStep} />}
          {activeStep === 2 && (
            <PaymentDetail infoUpdate={() => setActiveStep(1)} />
          )}
        </Grid>
        {lgMatches && (
          <Grid item xs={12} lg={4}>
            <Box sx={{ position: "sticky", top: "32px" }}>
              <EntryTimer />
              <PriceSection />
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default PNRPage;
