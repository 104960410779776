import {
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  alpha,
} from '@mui/material';
import styled from 'styled-components';

export const StyledDialogTitle = styled((props) => <DialogTitle {...props} />)(
  ({ theme }) => ({
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 25,
    paddingTop: 32,
    paddingBottom: 32,

    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      position: 'relative',
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: 20,
      paddingRight: 20,
      textAlign: 'left',
      borderBottom: `1px solid ${theme.palette.neutral.stroke}`,
    },
  })
);

export const StyledDialogContent = styled((props) => (
  <DialogContent {...props} />
))(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: 40,
  paddingRight: 40,
  paddingBottom: 40,

  [theme.breakpoints.down('md')]: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: '24px !important',
  },
}));

export const StyledInput = styled(TextField)(({ theme }) => {
  return {
    '& + label': {
      marginBottom: theme.spacing(1),
      fontSize: 16,
      transform: 'scale(1)',
      position: 'relative',
    },
    '& + label.Mui-focused': {
      color: theme.palette.neutral.darkgray,
    },
    '& .MuiInput-underline:after': {
      content: 'unset',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderWidth: 2.5,
        borderRadius: 4,
        borderColor: theme.palette.neutral.stroke,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderWidth: 2.5,
        boxShadow: `${alpha(theme.palette.primary.main, 0.3)} 0 0 0 4px`,
        borderColor: theme.palette.primary.main,
      },

      '& .MuiOutlinedInput-input': {
        position: 'relative',
        borderRadius: 4,
        fontSize: 16,
        width: '100%',
        padding: '11px 14px',
        boxShadow: 'none',
      },
    },
  };
});

export const StyledSocialButton = styled((props) => (
  <Button variant="outlined" fullWidth {...props} />
))`
  padding-top: ${({ theme }) => theme.spacing(1.625)};
  padding-bottom: ${({ theme }) => theme.spacing(1.625)};
  font-size: 16;
  color: ${({ theme }) => theme.palette.neutral.black};
`;
