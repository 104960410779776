import { styled } from "@mui/material/styles";
import { alpha, InputBase } from "@mui/material";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";

export const StyledInput = styled(OutlinedInput)(({ theme }) => {
  return {
    height: 48,
    "& + label": {
      marginBottom: theme.spacing(1),
      fontSize: 16,
      transform: "scale(1)",
      position: "relative",
    },
    "& + label.Mui-focused": {
      color: theme.palette.neutral.darkgray,
    },
    "&.MuiOutlinedInput-root": {
      fieldset: {
        borderWidth: 2.5,
        borderRadius: 4,
        borderColor: theme.palette.neutral.stroke,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 2.5,
        boxShadow: `${alpha(theme.palette.primary.main, 0.3)} 0 0 0 4px`,
        borderColor: theme.palette.primary.main,
      },

      "& .MuiOutlinedInput-input": {
        position: "relative",
        borderRadius: 4,
        borderWidth: 2,
        fontSize: 16,
        width: "100%",
        padding: "0 14px",
        boxShadow: "none",
        height: "100%",
      },
    },
  };
});

export const StyledTextField = styled(TextField)(({ theme }) => {
  return {
    "& + label": {
      marginBottom: theme.spacing(1),
      fontSize: 16,
      transform: "scale(1)",
      position: "relative",
    },
    "& + label.Mui-focused": {
      color: theme.palette.neutral.darkgray,
    },
    "& .MuiInput-underline:after": {
      content: "unset",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderWidth: 2.5,
        borderRadius: 4,
        borderColor: theme.palette.neutral.stroke,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 2.5,
        boxShadow: `${alpha(theme.palette.primary.main, 0.3)} 0 0 0 4px`,
        borderColor: theme.palette.primary.main,
      },

      "& .MuiOutlinedInput-input": {
        position: "relative",
        borderRadius: 4,
        fontSize: 16,
        width: "100%",
        padding: "11px 14px",
        boxShadow: "none",
      },
    },
  };
});

export const GroupStyledSelect = styled(InputBase)(({ theme }) => {
  return {
    "& .MuiSelect-select": {
      height: 48,
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiInputBase-input": {
      borderRadius: "4px 0 0 4px",
      border: `2.5px solid ${theme.palette.neutral.stroke}`,
      borderRight: 0,
      position: "relative",
      fontSize: 16,
      width: "100%",
      padding: "0 14px ",

      "&::after": {
        content: '""',
        position: "absolute",
        background: theme.palette.neutral.stroke,
        width: 2,
        right: 0,
        height: 28,
        top: "50%",
        transform: "translateY(-50%)",
      },

      "&:hover": {
        borderColor: theme.palette.primary.main,
      },

      "&:focus": {
        borderColor: theme.palette.primary.main,
        boxShadow: `${alpha(theme.palette.primary.main, 0.3)} 0 0 0 4px`,
      },
    },
  };
});

export const GroupStyledInput = styled(StyledInput)(({ theme }) => {
  return {
    "&.MuiOutlinedInput-root": {
      fieldset: {
        borderRadius: "0 4px 4px 0",
        borderLeft: "0",
        top: "-4.5px",
      },
      "&.Mui-focused fieldset": {
        borderWidth: 2.5,
        boxShadow: `${alpha(theme.palette.primary.main, 0.3)} 0 0 0 4px`,
        borderColor: theme.palette.primary.main,
        borderLeft: "0",
      },

      "& .MuiOutlinedInput-input": {
        position: "relative",
        borderRadius: 4,
        borderWidth: 2,
        fontSize: 16,
        width: "100%",
        padding: "11px 14px",
        boxShadow: "none",
      },
    },
  };
});

export const StyledDateInput = styled(StyledInput)(({ theme }) => {
  return {
    "&.MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        padding: "0 0 0 12px",
      },
    },
  };
});

export const StyledAutoCompleteInput = styled(StyledInput)(({ theme }) => {
  return {
    "&.MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        padding: "0 12px",
      },
    },
  };
});
