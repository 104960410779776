import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { StyledTextField } from '../../../components/_shared';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';

import Dialog from '@mui/material/Dialog';
import {
  ChangePassDialogTitle,
  ChangePassDialogContent,
  ChangePassDialogAction,
  ButtonSemiLarge,
} from './Profile.styled';
import { BiX } from 'react-icons/bi';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ChangePasswordDialog = ({ openDialog, onDialogChange }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleChangePassDialogClose = () => {
    onDialogChange(false);
  };
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const toggleShowCurrentPassword = () =>
    setShowCurrentPassword(!showCurrentPassword);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  return (
    <Dialog
      open={openDialog}
      onClose={handleChangePassDialogClose}
      aria-labelledby="date-grid-dialog-title"
      fullScreen={fullScreen}
    >
      <ChangePassDialogTitle sx={{ width: { xs: '100%', md: 520 } }}>
        <Stack spacing={0.5}>
          <Typography variant="h3" fontWeight="medium" color="common.black">
            Change password
          </Typography>
          {!fullScreen && (
            <Typography variant="body1" color="neutral.darkgray">
              Enter your current password and a new password
            </Typography>
          )}
        </Stack>
        {fullScreen && (
          <IconButton
            aria-label="close"
            onClick={handleChangePassDialogClose}
            sx={{
              position: 'absolute',
              right: 12,
              top: 9,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <BiX size="24px" />
          </IconButton>
        )}
      </ChangePassDialogTitle>
      <ChangePassDialogContent>
        <Stack spacing={2} width="100%">
          <FormControl fullWidth>
            <StyledTextField
              id="currentPass"
              type={showCurrentPassword ? 'text' : 'password'}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={toggleShowCurrentPassword}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        textDecorationLine: 'underline',
                        textUnderlineOffset: 3,
                        fontSize: 14,
                        lineHeight: 1.4,
                        cursor: 'pointer',
                      }}
                    >
                      {showCurrentPassword ? 'Hide' : 'Show'}
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
            <InputLabel htmlFor="password">Current Password</InputLabel>
          </FormControl>

          <FormControl fullWidth>
            <StyledTextField
              id="newPass"
              type={showNewPassword ? 'text' : 'password'}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={toggleShowNewPassword}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        textDecorationLine: 'underline',
                        textUnderlineOffset: 3,
                        fontSize: 14,
                        lineHeight: 1.4,
                        cursor: 'pointer',
                      }}
                    >
                      {showNewPassword ? 'Hide' : 'Show'}
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
            <InputLabel htmlFor="password">New Password</InputLabel>
          </FormControl>

          <FormControl fullWidth>
            <StyledTextField
              id="confirmPass"
              type={showConfirmPassword ? 'text' : 'password'}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={toggleShowConfirmPassword}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        textDecorationLine: 'underline',
                        textUnderlineOffset: 3,
                        fontSize: 14,
                        lineHeight: 1.4,
                        cursor: 'pointer',
                      }}
                    >
                      {showConfirmPassword ? 'Hide' : 'Show'}
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
            <InputLabel htmlFor="password">Confirm Password</InputLabel>
          </FormControl>
        </Stack>
      </ChangePassDialogContent>
      <ChangePassDialogAction>
        <ButtonSemiLarge
          fullWidth={fullScreen}
          onClick={handleChangePassDialogClose}
        >
          Cancel
        </ButtonSemiLarge>
        <ButtonSemiLarge
          variant="contained"
          onClick={handleChangePassDialogClose}
          autoFocus
          fullWidth={fullScreen}
        >
          Change Password
        </ButtonSemiLarge>
      </ChangePassDialogAction>
    </Dialog>
  );
};

export default ChangePasswordDialog;
