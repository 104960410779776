import { createContext, useState, useContext, useCallback } from "react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import AuthDialog from "./components/AuthDialogs";

export const AuthContext = createContext({
  token: "",
  user: null,
  isLoggedIn: false,
  openAuthModal: (type = "login") => {},
  login: (token) => {},
  logout: () => {},
});

const retrieveTokenAndUser = () => {
  const storedToken = localStorage.getItem("token");

  if (!storedToken) return null;

  try {
    const decoded = jwtDecode(storedToken.split(" ")[1]);

    // ? Check if storedToken has expired
    if (decoded.exp <= Date.now() / 1000) {
      localStorage.removeItem("token");
      return null;
    }

    return { token: storedToken };
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const AuthContextProvider = (props) => {
  const localAuth = retrieveTokenAndUser();
  const navigate = useNavigate();

  const [token, setToken] = useState(localAuth?.token ?? null);
  const [isAuthModalOpen, setAuthModalOpen] = useState(false);
  const [showRegister, setShowRegister] = useState(false);

  // ? convert token from a truthy value to a boolean value
  const userIsLoggedIn = !!token;

  const loginHandler = (
    // ? using dummy jwt
    token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IlRlc3QgVXNlciIsImV4cCI6MTUxNjIzOTAyMiwiaWF0IjoxNTE2MjM5MDIyfQ.uti3LDSOA16i1_IrMFReJrFJcIujeH0EHIUC8b5bHqE"
  ) => {
    try {
      localStorage.setItem("token", token);

      // axios.defaults.headers.common["Authorization"] = token;

      setToken(token);
    } catch (error) {
      console.log(error);
    }
  };

  const logoutHandler = useCallback(() => {
    navigate("/", { replace: true });
    setToken(null);
    localStorage.removeItem("token");
  }, [navigate]);

  const openAuthModal = (type = "login") => {
    setShowRegister(type === "signup");
    setAuthModalOpen(true);
  };

  const handleAuthModalClose = () => setAuthModalOpen(false);

  return (
    <AuthContext.Provider
      value={{
        token,
        isLoggedIn: userIsLoggedIn,
        openAuthModal,
        login: loginHandler,
        logout: logoutHandler,
      }}
    >
      {props.children}

      <AuthDialog
        open={isAuthModalOpen}
        onClose={handleAuthModalClose}
        showRegister={showRegister}
        onShowRegister={setShowRegister}
      />
    </AuthContext.Provider>
  );
};

export const RequireAuth = ({ children }) => {
  const { isLoggedIn } = useContext(AuthContext);
  let location = useLocation();

  if (!isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <>{children}</>;
};
