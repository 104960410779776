import { forwardRef, useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { BiUser, BiArrowBack } from "react-icons/bi";

import {
  StyledDropdownButton,
  StyledMenu,
  StyledIconButton,
} from "./Dropdown.elements";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const TicketsDropdown = ({
  anchorEl,
  open,
  onClick,
  travellers,
  onDone,
  onCancel,
}) => {
  const { breakpoints, palette } = useTheme();
  const smUp = useMediaQuery(breakpoints.up("sm"));

  const [adults, setAdults] = useState(travellers.adults);
  const decrementAdults = () => setAdults(adults - 1);
  const incrementAdults = () => setAdults(adults + 1);

  const [children, setChildren] = useState(travellers.children);
  const decrementChildren = () => setChildren(children - 1);
  const incrementChildren = () => setChildren(children + 1);

  const [infants, setInfants] = useState(travellers.infants);
  const decrementInfants = () => setInfants(infants - 1);
  const incrementInfants = () => setInfants(infants + 1);

  const handleDone = () => onDone({ adults, children, infants });

  const handleCancel = () => {
    onCancel();
    setAdults(travellers.adults);
    setChildren(travellers.children);
    setInfants(travellers.infants);
  };

  return (
    <>
      <StyledDropdownButton
        startIcon={<BiUser size="1.25rem" />}
        onClick={onClick}
      >
        {travellers.adults + travellers.children + travellers.infants}{" "}
        {smUp &&
          `Traveller${
            travellers.adults + travellers.children + travellers.infants > 1
              ? "s"
              : ""
          }`}
      </StyledDropdownButton>

      {smUp ? (
        <StyledMenu
          id="travellers-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCancel}
          PaperProps={{ style: { minWidth: 250, padding: 16 } }}
        >
          <Stack spacing={2} sx={{ mb: 3 }}>
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <Typography variant="inputLabel">Adult</Typography>
                <Typography variant="inputHelperText">18 above</Typography>
              </Stack>

              <Stack direction="row" alignItems="center">
                <StyledIconButton
                  disabled={adults <= 1}
                  onClick={decrementAdults}
                >
                  <RemoveIcon />
                </StyledIconButton>

                <Typography
                  variant="inputLabel"
                  sx={{ minWidth: 40, textAlign: "center" }}
                >
                  {adults}
                </Typography>

                <StyledIconButton
                  disabled={adults >= 99}
                  onClick={incrementAdults}
                >
                  <AddIcon />
                </StyledIconButton>
              </Stack>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <Typography variant="inputLabel">Children</Typography>
                <Typography variant="inputHelperText">2 - 17 years</Typography>
              </Stack>

              <Stack direction="row" alignItems="center">
                <StyledIconButton
                  disabled={children <= 0}
                  onClick={decrementChildren}
                >
                  <RemoveIcon />
                </StyledIconButton>

                <Typography
                  variant="inputLabel"
                  sx={{ minWidth: 40, textAlign: "center" }}
                >
                  {children}
                </Typography>

                <StyledIconButton
                  disabled={children >= 99}
                  onClick={incrementChildren}
                >
                  <AddIcon />
                </StyledIconButton>
              </Stack>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <Typography variant="inputLabel">Infants</Typography>
                <Typography variant="inputHelperText">0 - 2 years</Typography>
              </Stack>

              <Stack direction="row" alignItems="center">
                <StyledIconButton
                  disabled={infants <= 0}
                  onClick={decrementInfants}
                >
                  <RemoveIcon />
                </StyledIconButton>

                <Typography
                  variant="inputLabel"
                  sx={{ minWidth: 40, textAlign: "center" }}
                >
                  {infants}
                </Typography>

                <StyledIconButton
                  disabled={infants >= 99}
                  onClick={incrementInfants}
                >
                  <AddIcon />
                </StyledIconButton>
              </Stack>
            </Stack>
          </Stack>

          <Stack direction="row" justifyContent="flex-end">
            <Button variant="outline" onClick={handleCancel} sx={{ mr: 1 }}>
              Cancel
            </Button>

            <Button variant="contained" onClick={handleDone}>
              Done
            </Button>
          </Stack>
        </StyledMenu>
      ) : (
        <Dialog
          fullScreen
          open={open}
          onClose={handleCancel}
          TransitionComponent={Transition}
        >
          <Stack
            p={2}
            borderBottom={1}
            borderColor="neutral.stroke"
            direction="row"
            alignItems="center"
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCancel}
              aria-label="close"
              sx={{ mr: 0.5 }}
            >
              <BiArrowBack color={palette.neutral.gray} />
            </IconButton>
            <Typography variant="body1">Set Travellers</Typography>
          </Stack>
          <Box p={2}>
            <Stack spacing={2} sx={{ mb: 3 }}>
              <Stack direction="row" justifyContent="space-between">
                <Stack>
                  <Typography variant="inputLabel">Adult</Typography>
                  <Typography variant="inputHelperText">18 above</Typography>
                </Stack>

                <Stack direction="row" alignItems="center">
                  <StyledIconButton
                    disabled={adults <= 1}
                    onClick={decrementAdults}
                  >
                    <RemoveIcon />
                  </StyledIconButton>

                  <Typography
                    variant="inputLabel"
                    sx={{ minWidth: 40, textAlign: "center" }}
                  >
                    {adults}
                  </Typography>

                  <StyledIconButton
                    disabled={adults >= 99}
                    onClick={incrementAdults}
                  >
                    <AddIcon />
                  </StyledIconButton>
                </Stack>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Stack>
                  <Typography variant="inputLabel">Children</Typography>
                  <Typography variant="inputHelperText">
                    2 - 17 years
                  </Typography>
                </Stack>

                <Stack direction="row" alignItems="center">
                  <StyledIconButton
                    disabled={children <= 0}
                    onClick={decrementChildren}
                  >
                    <RemoveIcon />
                  </StyledIconButton>

                  <Typography
                    variant="inputLabel"
                    sx={{ minWidth: 40, textAlign: "center" }}
                  >
                    {children}
                  </Typography>

                  <StyledIconButton
                    disabled={children >= 99}
                    onClick={incrementChildren}
                  >
                    <AddIcon />
                  </StyledIconButton>
                </Stack>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Stack>
                  <Typography variant="inputLabel">Infants</Typography>
                  <Typography variant="inputHelperText">0 - 2 years</Typography>
                </Stack>

                <Stack direction="row" alignItems="center">
                  <StyledIconButton
                    disabled={infants <= 0}
                    onClick={decrementInfants}
                  >
                    <RemoveIcon />
                  </StyledIconButton>

                  <Typography
                    variant="inputLabel"
                    sx={{ minWidth: 40, textAlign: "center" }}
                  >
                    {infants}
                  </Typography>

                  <StyledIconButton
                    disabled={infants >= 99}
                    onClick={incrementInfants}
                  >
                    <AddIcon />
                  </StyledIconButton>
                </Stack>
              </Stack>
            </Stack>
          </Box>

          <Stack
            p={2}
            borderTop={1}
            borderColor="neutral.stroke"
            direction="row"
            justifyContent="space-between"
            sx={{ position: "fixed", bottom: 0, width: "100%" }}
          >
            <Button size="large" fullWidth onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={handleDone}
            >
              Done
            </Button>
          </Stack>
        </Dialog>
      )}
    </>
  );
};

export default TicketsDropdown;
