import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const DividerTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  marginBottom: 24,

  '&::after': {
    content: '""',
    flex: '1 1 0%',
    // height: 1,
    borderTop: `1px solid ${theme.palette.neutral.placeholder}`,
    marginLeft: 16,
  },
}));
