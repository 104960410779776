const Baggage = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 4.25002V3.33335C13.3333 2.41669 12.5833 1.66669 11.6666 1.66669H8.33325C7.41658 1.66669 6.66658 2.41669 6.66658 3.33335V4.25002C4.74992 4.66669 3.33325 6.33335 3.33325 8.33335V16.6667C3.33325 17.5834 4.08325 18.3334 4.99992 18.3334H14.9999C15.9166 18.3334 16.6666 17.5834 16.6666 16.6667V8.33335C16.6666 6.33335 15.2499 4.66669 13.3333 4.25002ZM8.33325 3.33335H11.6666V4.16669H8.33325V3.33335ZM12.4999 16.6667H7.49992V12.5H12.4999V16.6667ZM14.9999 16.6667H14.1666V12.5C14.1666 11.5834 13.4166 10.8334 12.4999 10.8334H7.49992C6.58325 10.8334 5.83325 11.5834 5.83325 12.5V16.6667H4.99992V8.33335C4.99992 6.91669 6.16658 5.83335 7.49992 5.83335H12.4999C13.9166 5.83335 14.9999 6.91669 14.9999 8.33335V16.6667Z"
        fill="#484848"
      />
      <path
        d="M11.6667 9.16667H8.33333C7.83333 9.16667 7.5 8.83333 7.5 8.33333C7.5 7.83333 7.83333 7.5 8.33333 7.5H11.6667C12.1667 7.5 12.5 7.83333 12.5 8.33333C12.5 8.83333 12.1667 9.16667 11.6667 9.16667Z"
        fill="#484848"
      />
    </svg>
  );
};

export default Baggage;
