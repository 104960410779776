import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const StyledButton = styled((props) => (
  <Button variant="contained" fullWidth {...props} />
))(({ theme }) => ({
  height: '100%',
}));

export const CustomIconButton = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  height: '45px',
  width: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  '& svg': {
    fill: theme.palette.neutral.gray,
  },

  '&:hover': {
    backgroundColor: theme.palette.neutral.offwhite,
    svg: {
      fill: theme.palette.common.black,
    },
  },
}));
