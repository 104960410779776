import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export const SideTabs = styled(Tabs)(({ theme }) => ({
  padding: 8,
  background: theme.palette.common.white,
  borderRadius: 8,
  '&.MuiTabs-vertical': {
    borderRight: '0',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

export const SideTab = styled(Tab)(({ theme }) => ({
  justifyContent: 'flex-start',
  padding: '0 8px',
  minHeight: '52px',
  maxWidth: '100%',
  borderRadius: '4px',
  '& .MuiTab-iconWrapper': {
    marginRight: 12,
  },
  '&.Mui-selected': {
    background: theme.palette.neutral.offwhite,
    '& .MuiTab-iconWrapper': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
}));

export const ButtonSemiLarge = styled(Button)(({ theme }) => ({
  paddingTop: 12,
  paddingBottom: 12,
}));

export const DelButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.error.main,
  borderWidth: 2,
  paddingTop: 10,
  paddingBottom: 10,
  '&:hover': {
    borderWidth: 2,
    borderColor: theme.palette.error.main,
  },
}));

// Dialog
export const ChangePassDialogTitle = styled(DialogTitle)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 700,
  paddingTop: 32,
  paddingBottom: 32,

  [theme.breakpoints.down('md')]: {
    position: 'relative',
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'left',
    borderBottom: `1px solid ${theme.palette.neutral.stroke}`,
  },
}));

export const ChangePassDialogContent = styled((props) => (
  <DialogContent {...props} />
))(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: 40,
  paddingRight: 40,
  paddingBottom: 24,

  [theme.breakpoints.down('md')]: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: '24px !important',
  },
}));

export const ChangePassDialogAction = styled(DialogActions)(({ theme }) => ({
  paddingLeft: 40,
  paddingRight: 40,
  paddingBottom: 40,

  [theme.breakpoints.down('md')]: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
}));
