import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

export const StyledList = styled(List)(({ theme }) => ({
  listStyleType: 'disc',
  paddingLeft: 24,
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: 'list-item',
  paddingLeft: 4,
}));
