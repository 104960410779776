import React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

import { BiTransfer, BiUser } from "react-icons/bi";

import { Dot, FlexBox } from "../../../components/_shared";
import { FlightCardSummary } from "../../../components/FlightCard";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const flightDetails = [
  { name: "Flight1", date: "Sat, Mar 19" },
  { name: "Flight2", date: "Sun, Apr 24" },
];

const FlightDetail = () => {
  const { palette } = useTheme();

  const theme = useTheme();
  const mdMatches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <FlexBox fd="column" mb={3}>
        <Stack direction={"row"} alignItems="center" mb={{ xs: 0.5, sm: 0 }}>
          <Typography variant="bodyShrink" color={"neutral.darkgray"}>
            Round Trip
          </Typography>
          <Dot />
          <FlexBox gap="4px" items="center">
            {mdMatches ? <BiUser size="1.25rem" /> : <BiUser size="1.125rem" />}
            <Typography variant="bodyShrink" color={"neutral.darkgray"}>
              1
            </Typography>
          </FlexBox>
          <Dot />
          <Typography variant="bodyShrink" color={"neutral.darkgray"}>
            Economy
          </Typography>
        </Stack>
        <Stack spacing={1.25} direction="row" alignItems="center">
          <Typography variant="h2" fontWeight={"medium"}>
            Kathmandu
          </Typography>
          <BiTransfer color={palette.primary.main} size="1.25rem" />
          <Typography variant="h2" fontWeight={"medium"}>
            London
          </Typography>
        </Stack>
      </FlexBox>

      <Paper
        elevation={0}
        sx={{ borderRadius: "8px", overflow: "hidden", mb: 3 }}
        className="st-flight-combination"
      >
        {flightDetails.map((flightDetail, index) => (
          <FlightCardSummary key={index} detail={flightDetail} />
        ))}
      </Paper>
    </>
  );
};

export default FlightDetail;
