import { useContext, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Container,
  Stack,
  Box,
  Avatar,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ListItemIcon from "@mui/material/ListItemIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import { BiCaretDown } from "react-icons/bi";
import { AuthContext } from "../../../auth-context";
import {
  StyledImage,
  StyledButton,
  StyledMenu,
  StyledMenuItem,
} from "./index.elements";
import UserIcon from "../../../assets/icons/User.icon";
import TicketIcon from "../../../assets/icons/Ticket.icon";
import LogoutIcon from "../../../assets/icons/Logout.icon";

import ContactMenu from "./components/ContactMenu";

const Header = () => {
  const { palette, breakpoints } = useTheme();

  const smMatches = useMediaQuery(breakpoints.up("sm"));
  const mdMatches = useMediaQuery(breakpoints.up("md"));

  const { isLoggedIn, openAuthModal, logout } = useContext(AuthContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isLandingPage = pathname === "/";

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleLogin = () => openAuthModal();

  const handleSignUp = () => openAuthModal("signup");

  const handleLogout = () => {
    setAnchorEl(null);
    logout();
  };

  const handleProfileDetails = () => {
    setAnchorEl(null);
    navigate("/profile", { state: { tab: 0 } });
  };

  const handleMyTickets = () => {
    setAnchorEl(null);
    navigate("/profile", { state: { tab: 1 } });
  };

  return (
    <AppBar
      position={isLandingPage ? "absolute" : "relative"}
      elevation={0}
      sx={{
        borderBottom: isLandingPage
          ? "unset"
          : `1px solid ${palette.neutral.stroke}`,
        backgroundColor: isLandingPage ? "transparent" : "common.white",
      }}
    >
      <Toolbar sx={{ height: 72, p: { xs: 0, md: 0, lg: 0 } }}>
        <Container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Link to="/">
            <StyledImage
              src={isLandingPage ? "/logo_light.png" : "/logo.png"}
            />
          </Link>
          <Stack direction="row">
            {mdMatches && <ContactMenu isLandingPage={isLandingPage} />}
            {isLoggedIn ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <StyledButton
                  variant="text"
                  startIcon={
                    <Avatar
                      alt="Wade"
                      src="/broken-image.jpg"
                      sx={{ width: 32, height: 32 }}
                    />
                  }
                  endIcon={
                    <BiCaretDown
                      color={
                        isLandingPage
                          ? palette.common.white
                          : palette.common.black
                      }
                      size="16px"
                    />
                  }
                  onClick={handleClick}
                >
                  <Typography
                    variant="body1"
                    color={
                      isLandingPage
                        ? palette.common.white
                        : palette.common.black
                    }
                  >
                    Wade
                  </Typography>
                </StyledButton>
                <StyledMenu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <StyledMenuItem onClick={handleProfileDetails}>
                    <ListItemIcon>
                      <UserIcon />
                    </ListItemIcon>
                    Profile Details
                  </StyledMenuItem>

                  <StyledMenuItem onClick={handleMyTickets}>
                    <ListItemIcon>
                      <TicketIcon />
                    </ListItemIcon>
                    My Tickets
                  </StyledMenuItem>

                  <StyledMenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    Logout
                  </StyledMenuItem>
                </StyledMenu>
              </Box>
            ) : (
              <>
                {smMatches && (
                  <Button
                    variant="outlined"
                    sx={{
                      mr: 1.5,
                      ...(isLandingPage
                        ? {
                            borderColor: palette.primary.main,
                            color: palette.common.white,
                          }
                        : {}),
                    }}
                    onClick={handleLogin}
                  >
                    Login
                  </Button>
                )}
                <Button variant="contained" onClick={handleSignUp}>
                  Sign Up
                </Button>
              </>
            )}
          </Stack>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
