import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Footer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px 20px',
  alignItems: 'center',
  borderTop: `1px solid ${theme.palette.neutral.stroke}`,
}));
