const LocationPin = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 11.6667C11.8384 11.6667 13.3334 10.1717 13.3334 8.33333C13.3334 6.495 11.8384 5 10.0001 5C8.16175 5 6.66675 6.495 6.66675 8.33333C6.66675 10.1717 8.16175 11.6667 10.0001 11.6667ZM10.0001 6.66667C10.9192 6.66667 11.6667 7.41417 11.6667 8.33333C11.6667 9.2525 10.9192 10 10.0001 10C9.08091 10 8.33341 9.2525 8.33341 8.33333C8.33341 7.41417 9.08091 6.66667 10.0001 6.66667Z"
        fill="#484848"
      />
      <path
        d="M9.51666 18.1784C9.6577 18.2791 9.82668 18.3332 9.99999 18.3332C10.1733 18.3332 10.3423 18.2791 10.4833 18.1784C10.7367 17.9992 16.6908 13.7 16.6667 8.33335C16.6667 4.65752 13.6758 1.66669 9.99999 1.66669C6.32416 1.66669 3.33333 4.65752 3.33333 8.32919C3.30916 13.7 9.26333 17.9992 9.51666 18.1784ZM9.99999 3.33335C12.7575 3.33335 15 5.57585 15 8.33752C15.0175 12.0359 11.3433 15.3567 9.99999 16.4459C8.65749 15.3559 4.98249 12.0342 4.99999 8.33335C4.99999 5.57585 7.24249 3.33335 9.99999 3.33335Z"
        fill="#484848"
      />
    </svg>
  );
};

export default LocationPin;
