import { styled } from '@mui/material/styles';

export const PaymentLogo = styled((props) => {
  return <img {...props} />;
})(() => ({
  width: 'auto',
  height: '32px',
  margin: '20px 0',
  marginLeft: '7px',
}));
