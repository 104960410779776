import { forwardRef } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";

import { BiCheck, BiX } from "react-icons/bi";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { BottomDialog } from "../../../components/_shared";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SortOption = ({ option }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1" color="neutral.darkgray">
          {option.name}
        </Typography>
        <Stack direction="row" spacing={0.75} alignItems="center">
          {option.price && (
            <Typography variant="body2" color="neutral.darkgray">
              {option.price}
            </Typography>
          )}
          {option.price && option.duration && <Typography>•</Typography>}
          {option.duration && (
            <Typography variant="body2" color="neutral.darkgray">
              {option.duration}
            </Typography>
          )}
          {option.time && (
            <Typography variant="body2" color="neutral.darkgray">
              {option.time}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

const SortMenu = ({
  anchorEl,
  onAnchorEl,
  index: selection,
  onIndexChange,
  options = [],
}) => {
  const { breakpoints, palette } = useTheme();
  const smUp = useMediaQuery(breakpoints.up("sm"));

  const openSortMenu = Boolean(anchorEl);

  const handleSortItemClick = (index) => (event) => {
    onIndexChange(index);
    onAnchorEl(null);
  };

  const handleSortClose = () => {
    onAnchorEl(null);
  };

  return smUp ? (
    <Menu
      id="sort-menu"
      anchorEl={anchorEl}
      open={openSortMenu}
      onClose={handleSortClose}
      MenuListProps={{ "aria-labelledby": "sort-button", role: "listbox" }}
    >
      {options.map((option, index) => {
        return (
          <MenuItem
            elevation={0}
            key={index}
            selected={index === selection}
            onClick={handleSortItemClick(index)}
            sx={{ width: "320px" }}
          >
            <Stack sx={{ width: "100%" }} direction="row" alignItems="center">
              {index === selection ? (
                <>
                  <ListItemIcon>
                    <BiCheck color={palette.primary.main} />
                  </ListItemIcon>
                  <SortOption option={option} />
                </>
              ) : (
                <ListItemText inset>
                  <SortOption option={option} />
                </ListItemText>
              )}
            </Stack>
          </MenuItem>
        );
      })}
    </Menu>
  ) : (
    <BottomDialog
      open={openSortMenu}
      onClose={handleSortClose}
      TransitionComponent={Transition}
      fullWidth
    >
      <Stack
        p={2}
        borderBottom={1}
        borderColor="neutral.stroke"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="body1">Sort</Typography>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleSortClose}
          aria-label="close"
          sx={{ mr: 0.5 }}
        >
          <BiX color={palette.neutral.gray} />
        </IconButton>
      </Stack>
      <Box p={1}>
        {options.map((option, index) => (
          <MenuItem
            key={index}
            selected={index === selection}
            onClick={handleSortItemClick(index)}
          >
            {index === selection ? (
              <>
                <ListItemIcon>
                  <BiCheck color={palette.primary.main} />
                </ListItemIcon>
                <SortOption option={option} />
              </>
            ) : (
              <ListItemText inset>
                <SortOption option={option} />
              </ListItemText>
            )}
          </MenuItem>
        ))}
      </Box>
    </BottomDialog>
  );
};

export default SortMenu;
