import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const Filters = () => {
  return (
    <>
      <Typography
        variant="h4"
        fontWeight="medium"
        mb={3.5}
        sx={{ display: { xs: 'none', lg: 'block' } }}
      >
        Filter by
      </Typography>

      {/* Filter group */}
      <Stack spacing={1.25}>
        {/* Filter title */}
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="body2"
            fontWeight="medium"
            color="neutral.darkgray"
          >
            Stops
          </Typography>
          <Typography
            variant="body2"
            fontWeight="medium"
            color="neutral.darkgray"
          >
            From
          </Typography>
        </Stack>

        {/* Filters */}
        <Stack spacing={0}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <FormControlLabel control={<Checkbox />} label="No Stop" />
            <Typography variant="body1" color="neutral.darkgray">
              Rs. 1,00,000
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <FormControlLabel control={<Checkbox />} label="1 Stop" />
            <Typography variant="body1" color="neutral.darkgray">
              Rs. 1,00,000
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <FormControlLabel control={<Checkbox />} label="2 Stop" />
            <Typography variant="body1" color="neutral.darkgray">
              Rs. 1,00,000
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Filters;
