import { forwardRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";

import { useTheme } from "@mui/material/styles";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { BiTransfer, BiSearch, BiX, BiArrowBack } from "react-icons/bi";

import moment from "moment";

import { BottomDialog } from "../../../components/_shared";
import { TabPanel, a11yProps } from "../../../components/TabPanel";
import { DomesticFlight, InternationalFlight } from "../../Home/components";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Header = ({ tripType, data }) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, index) => setTabIndex(index);

  const goBack = (e) => {
    e.stopPropagation();
    navigate("/");
  };

  return (
    <>
      <AppBar
        position="sticky"
        sx={{ bgcolor: palette.primary.dark }}
        onClick={handleOpen}
      >
        <Toolbar sx={{ py: 1.5 }}>
          <Stack
            flexGrow={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" spacing={2}>
              <IconButton onClick={goBack}>
                <BiArrowBack color={palette.neutral.white} size="20px"/>
              </IconButton>

              <Stack spacing={0.5}>
                {tripType === "Multi-city" ? (
                  <Typography fontWeight="medium">Multi-Cities</Typography>
                ) : (
                  <Stack direction="row" spacing={0.5}>
                    <Typography fontWeight="medium">
                      {data?.from?.city || "Kathmandu"}
                    </Typography>
                    {true ? (
                      <ArrowRightAltIcon />
                    ) : (
                      <BiTransfer color={palette.neutral.white} />
                    )}
                    <Typography fontWeight="medium">
                      {data?.to?.city || "London"}
                    </Typography>
                  </Stack>
                )}

                {tripType !== "Multi-city" && (
                  <Typography variant="body2">
                    {moment(data?.departureDate).format("ddd, MMM DD")}
                    {data?.returnDate &&
                      " - " + moment(data.returnDate).format("ddd, MMM DD")}
                  </Typography>
                )}
              </Stack>
            </Stack>

            <BiSearch color={palette.neutral.white} />
          </Stack>
        </Toolbar>
      </AppBar>

      <BottomDialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        fullWidth
      >
        <Stack
          p={2}
          borderBottom={1}
          borderColor="neutral.stroke"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h3" fontWeight="medium">
            Modify Search
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ mr: 0.5 }}
          >
            <BiX color={palette.neutral.gray} />
          </IconButton>
        </Stack>

        <Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth">
          <Tab label="International Flights" {...a11yProps(0)} />
          <Tab label="Domestic Flights" {...a11yProps(1)} />
        </Tabs>

        <TabPanel value={tabIndex} index={0}>
          <InternationalFlight />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <DomesticFlight />
        </TabPanel>
      </BottomDialog>
    </>
  );
};

export default Header;
