const Facebook = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.397 20.997V12.801H16.162L16.573 9.59205H13.397V7.54805C13.397 6.62205 13.655 5.98805 14.984 5.98805H16.668V3.12705C15.8487 3.03924 15.0251 2.99684 14.201 3.00005C11.757 3.00005 10.079 4.49205 10.079 7.23105V9.58605H7.33203V12.795H10.085V20.997H13.397Z"
        fill="#757575"
      />
    </svg>
  );
};

export default Facebook;
