import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

export const StyledImage = styled((props) => {
  return (
    <figure>
      <img alt="" {...props} />
    </figure>
  );
})(({ theme }) => ({
  height: '48px',
  width: 'auto',
  objectFit: 'contain',

  // [theme.breakpoints.down('md')]: {
  //   width: '28px',
  //   height: '28px',
  // },
}));

export const StyledIconButton = styled(IconButton)`
  border: ${({ theme }) => `1px solid ${theme.palette.neutral.stroke}`};
`;
