import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { BiTimer } from 'react-icons/bi';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';

import SessionExpiredModal from '../../../components/SessionExpiredModal';

const EntryTimer = () => {
  const { palette } = useTheme();

  const [openSessionExpiredModal, setOpenSessionExpiredModal] = useState(false);
  const handleSessionExpiredModalOpen = () => setOpenSessionExpiredModal(true);

  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: '8px',
        mb: 3,
        overflow: 'hidden',
      }}
    >
      <Stack direction={'row'} alignItems="center" spacing="12px" p={2.5}>
        <BiTimer color={palette.neutral.gray} />
        <Typography color={'neutral.darkgray'}>
          Please complete within &nbsp;
          <Typography
            component="span"
            color={palette.primary.main}
            fontWeight="medium"
          >
            15:00
          </Typography>
          &nbsp; min.
        </Typography>
      </Stack>

      {/* ----- Show this modal when time limit expires` ----- */}
      <SessionExpiredModal
        openDialog={openSessionExpiredModal}
        onDialogChange={setOpenSessionExpiredModal}
      />
    </Paper>
  );
};

export default EntryTimer;
