import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  StyledDialogTitle,
  StyledDialogContent,
  StyledSocialButton,
} from './AuthDialogs.elements';
import LoginForm from './LoginDialog';
import SignUpForm from './SignUpDialog';
import GoogleIcon from '../../assets/icons/Google.icon';
import FacebookIcon from '../../assets/icons/Facebook.icon';
import { BiX } from 'react-icons/bi';

const AuthDialog = ({ open, onClose, showRegister, onShowRegister }) => {
  const { palette, breakpoints } = useTheme();
  const fullScreen = useMediaQuery(breakpoints.down('md'));

  const changeToSignUp = () => onShowRegister(true);

  const changeToLogin = () => onShowRegister(false);

  const responseGoogle = (response) => console.log(response);

  const responseFacebook = (response) => console.log(response);

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
      <StyledDialogTitle sx={{ width: { xs: '100%', md: 520 } }}>
        {showRegister ? 'Sign up' : 'Login'} to Sincere Travels
        {fullScreen && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 12,
              top: 9,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <BiX size="24px" />
          </IconButton>
        )}
      </StyledDialogTitle>
      <StyledDialogContent>
        {showRegister ? (
          <SignUpForm onClose={onClose} />
        ) : (
          <LoginForm onClose={onClose} />
        )}

        <GoogleLogin
          clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
          render={({ onClick, disabled }) => (
            <StyledSocialButton
              onClick={onClick}
              disabled={disabled}
              sx={{ mb: 1.5 }}
              startIcon={<GoogleIcon />}
            >
              Login with Google
            </StyledSocialButton>
          )}
        />

        <FacebookLogin
          appId="1088597931155576"
          callback={responseFacebook}
          render={({ onClick, disabled }) => (
            <StyledSocialButton
              onClick={onClick}
              disabled={disabled}
              sx={{ mb: { xs: 3, md: 4 } }}
              startIcon={<FacebookIcon />}
            >
              Login with Facebook
            </StyledSocialButton>
          )}
        />

        {showRegister ? (
          <Typography
            sx={{
              mb: { xs: 3, md: 4 },
              color: palette.neutral.black,
              fontWeight: '700',
            }}
          >
            Already have an account?{' '}
            <Link
              sx={{ textDecoration: 'none', cursor: 'pointer' }}
              onClick={changeToLogin}
            >
              Login
            </Link>
          </Typography>
        ) : (
          <Typography
            sx={{
              mb: { xs: 3, md: 4 },
              color: palette.neutral.black,
              fontWeight: '700',
            }}
          >
            Don't have an account?{' '}
            <Link
              sx={{ textDecoration: 'none', cursor: 'pointer' }}
              onClick={changeToSignUp}
            >
              Sign up now
            </Link>
          </Typography>
        )}

        <Typography
          sx={{
            fontSize: 14,
            textAlign: 'center',
            color: palette.neutral.gray,
          }}
        >
          By creating an account you agree to our
          <br />
          <Link sx={{ color: palette.neutral.black }}>
            Terms of Use
          </Link> and{' '}
          <Link sx={{ color: palette.neutral.black }}>Privacy Policy</Link>
        </Typography>
      </StyledDialogContent>
    </Dialog>
  );
};

export default AuthDialog;
