import { useEffect, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";

export const Progress = ({ loading = false, progress = 0 }) => {
  const [progressDummy, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) return 100;

        const diff = Math.random() * 50;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    if (progressDummy === 100) clearInterval(timer);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return progressDummy !== 100 ? (
    <LinearProgress variant="determinate" value={progressDummy} />
  ) : null;
};

export default Progress;
