import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { AuthContext } from "../../auth-context";
import {
  SideTab,
  SideTabs,
  ButtonSemiLarge,
  DelButton,
} from "./components/Profile.styled";
import InfoRow from "./components/InfoRow";

import Ticket from "../../assets/icons/Ticket.icon";

import { BiUser, BiLogOut } from "react-icons/bi";

import { SincereCard } from "../../components/_shared";

import ChangePasswordDialog from "./components/ChangePasswordDialog";
import MyTicket from "./components/MyTicket";
import EditBasic from "./components/EditBasic";
import EditContact from "./components/EditContact";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const Profile = () => {
  const { logout } = useContext(AuthContext);
  const { state = 0 } = useLocation();
  const [value, setValue] = useState(state.tab ? state.tab : 0);

  const theme = useTheme();
  const lgMatches = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    setValue(state.tab);
  }, [state]);

  const [openEditBasicInfo, setOpenEditBasicInfo] = useState(false);
  const [openEditContactInfo, setOpenEditContactInfo] = useState(false);

  const [openChangePassDialog, setOpenChangePassDialog] = useState(false);
  const handleChangePassDialogOpen = () => setOpenChangePassDialog(true);

  const handleChange = (event, newValue) => setValue(newValue);

  const handleEditProfileEdit = () => setOpenEditBasicInfo(true);
  const closeEditProfileEdit = () => setOpenEditBasicInfo(false);

  const handleContactInfoEdit = () => setOpenEditContactInfo(true);
  const closeContactInfoEdit = () => setOpenEditContactInfo(false);

  return (
    <Container className="st-no-footer" fixed sx={{ mt: 5 }}>
      <Grid container spacing={6}>
        <Grid
          item
          xs={12}
          lg={3}
          sx={{ position: "sticky", top: "-40px", zIndex: "99" }}
        >
          <SideTabs
            orientation={!lgMatches ? "horizontal" : "vertical"}
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{
              borderRight: 1,
              borderColor: "divider",
              position: "sticky",
              top: 32,
              boxShadow: { xs: theme.shadows[1], lg: theme.shadows[0] },
            }}
          >
            <SideTab
              icon={<BiUser />}
              iconPosition="start"
              label="Profile Details"
              id=""
              sx={{
                flexGrow: { xs: 1, lg: 0 },
                justifyContent: { xs: "center", lg: "flex-start" },
              }}
            />
            <SideTab
              icon={<Ticket />}
              iconPosition="start"
              label="My Ticket"
              id=""
              sx={{
                flexGrow: { xs: 1, lg: 0 },
                justifyContent: { xs: "center", lg: "flex-start" },
              }}
            />
            {lgMatches && (
              <SideTab
                icon={<BiLogOut />}
                iconPosition="start"
                label="Log out"
                id=""
                onClick={logout}
              />
            )}
          </SideTabs>
        </Grid>
        <Grid item xs={12} lg={9}>
          <TabPanel value={value} index={0}>
            {!openEditBasicInfo && !openEditContactInfo && (
              <>
                <Typography variant="h2" fontWeight="medium" mb={4}>
                  Profile Details
                </Typography>

                <SincereCard sx={{ mb: { xs: "24px", md: "32px" } }}>
                  <SincereCard.Header>
                    <Typography variant="h4" sx={{ fontWeight: "medium" }}>
                      Basic Information
                    </Typography>
                    <Button variant="text" onClick={handleEditProfileEdit}>
                      Edit
                    </Button>
                  </SincereCard.Header>

                  <Box pt={0.5} pb={2}>
                    <InfoRow title="Photo">
                      <Avatar
                        alt="Profile image"
                        src="/profile-image.jpg"
                        sx={{ width: 64, height: 64 }}
                      />
                    </InfoRow>
                    <InfoRow title="Name">
                      <Typography variant="body1" color="common.black">
                        Wade Warden
                      </Typography>
                    </InfoRow>
                    <InfoRow title="Date of Birth">
                      <Typography variant="body1" color="common.black">
                        March 24, 1994
                      </Typography>
                    </InfoRow>
                    <InfoRow title="Gender">
                      <Typography variant="body1" color="common.black">
                        Male
                      </Typography>
                    </InfoRow>
                    <InfoRow title="Nationality">
                      <Typography variant="body1" color="common.black">
                        Nepalese
                      </Typography>
                    </InfoRow>
                  </Box>
                </SincereCard>
              </>
            )}
            {openEditBasicInfo && (
              <>
                <EditBasic
                  onFinish={closeEditProfileEdit}
                  data={{
                    img: "/profile-image.jpg",
                    name: "Wade Warden",
                    dob: "March 24, 1994",
                    gender: "Male",
                    nationality: "Nepalese",
                  }}
                />
                <Box mb={5} />
              </>
            )}
            {!openEditContactInfo && !openEditBasicInfo && (
              <SincereCard sx={{ mb: { xs: "24px", md: "32px" } }}>
                <SincereCard.Header>
                  <Typography variant="h4" sx={{ fontWeight: "medium" }}>
                    Contact Details
                  </Typography>
                  <Button variant="text" onClick={handleContactInfoEdit}>
                    Edit
                  </Button>
                </SincereCard.Header>

                <Box pt={0.5} pb={2}>
                  <InfoRow title="Email">
                    <Typography variant="body1" color="common.black">
                      wadewardedn1994@gmail.com
                    </Typography>
                  </InfoRow>
                  <InfoRow title="Phone">
                    <Typography variant="body1" color="common.black">
                      +977 9841782638
                    </Typography>
                  </InfoRow>
                </Box>
              </SincereCard>
            )}
            {openEditContactInfo && (
              <>
                <EditContact
                  onFinish={closeContactInfoEdit}
                  data={{
                    email: "wadewardedn1994@gmail.com",
                    phone: "+977 9841782638",
                  }}
                />
                <Box mb={5}></Box>
              </>
            )}
            {!openEditBasicInfo && !openEditContactInfo && (
              <>
                {" "}
                <SincereCard sx={{ mb: { xs: "24px", md: "32px" } }}>
                  <SincereCard.Header>
                    <Typography variant="h4" sx={{ fontWeight: "medium" }}>
                      Password and authentication
                    </Typography>
                  </SincereCard.Header>

                  <Box pt={0.5} pb={2}>
                    <Box p={2.5}>
                      <ButtonSemiLarge
                        variant="contained"
                        onClick={handleChangePassDialogOpen}
                      >
                        Change Password
                      </ButtonSemiLarge>

                      <ChangePasswordDialog
                        openDialog={openChangePassDialog}
                        onDialogChange={setOpenChangePassDialog}
                      />
                    </Box>
                  </Box>
                </SincereCard>
                <DelButton color="error" variant="outlined">
                  Delete Account
                </DelButton>
              </>
            )}
            {/* ------------------------------- edit basic info ------------------------------- */}
          </TabPanel>

          <TabPanel value={value} index={1}>
            <MyTicket />
          </TabPanel>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Profile;
