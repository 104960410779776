import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

import Card from "@mui/material/Card";
import { CardActionArea } from "@mui/material";

import {
  BiRightArrowAlt,
  BiSortAlt2,
  BiChevronDown,
  BiTable,
  BiSliderAlt,
  BiChevronLeft,
  BiChevronRight,
} from "react-icons/bi";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import moment from "moment";

// Shared components
import { Dropdown, TicketsDropdown, Progress } from "../../components/_shared";
import { FlightCard } from "../../components/FlightCard";
import {
  OneWaySearch,
  RoundTripSearch,
  MultiCitySearch,
} from "../../components/FlightSearchTool";
import Footer from "../../components/Layout/Footer";

// Local components
import ResultHeader from "./components/Header";
import SortMenu from "./components/SortMenu";
import DateGrid from "./components/DateGridDialog";
import Filters from "./components/Filters";
import FullScreenDialog from "./components/FullScreenDialog";

function handleBreadcrumbClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const options = [
  { name: "Best", price: "NPR. 45,600", duration: "7h 55m" },
  { name: "Cheapest", price: "Rs. 32,210" },
  { name: "Fastest", duration: "5h 45m" },
  { name: "Earliest", time: "7:00 AM" },
];

const FLIGHT_TYPES = ["International", "Domestic Flight"];

const TRIP_TYPES = ["One-way", "Round Trip", "Multi-city"];

const NATIONALITIES = ["American", "Nepali", "Ukrainian"];

const CLASSES = ["Economy", "Premium Economy", "Business", "First Class"];

const Result = () => {
  const { breakpoints, palette } = useTheme();
  const mdUp = useMediaQuery(breakpoints.up("md"));

  const [anchorTraveller, setAnchorTraveller] = useState(null);
  const openTraveller = Boolean(anchorTraveller);

  const handleClickTravellerItem = (event) =>
    setAnchorTraveller(event.currentTarget);

  const [anchorFlightType, setAnchorFlightType] = useState(null);
  const [selectedFlightType, setSelectedFlightType] = useState(0);
  const openFlightType = Boolean(anchorFlightType);

  const [anchorTripType, setAnchorTripType] = useState(null);
  const [selectedTripType, setSelectedTripType] = useState(0);
  const openTripType = Boolean(anchorTripType);

  const [travellers, setTravellers] = useState({
    adults: 1,
    children: 0,
    infants: 0,
  });

  const [anchorNationality, setAnchorNationality] = useState(null);
  const [selectedNationality, setSelectedNationality] = useState(null);
  const openNationality = Boolean(anchorNationality);

  const [anchorClass, setAnchorClass] = useState(null);
  const [selectedClass, setSelectedClass] = useState(0);
  const openClass = Boolean(anchorClass);

  useEffect(() => {
    // handle domestic trip not having multi-city option in trip type
    if (
      selectedFlightType === FLIGHT_TYPES.indexOf("Domestic Flight") &&
      selectedTripType === TRIP_TYPES.indexOf("Multi-city")
    )
      setSelectedTripType(TRIP_TYPES.indexOf("One-way"));
  }, [selectedFlightType, selectedTripType]);

  const handleClickFlightTypeItem = (event) =>
    setAnchorFlightType(event.currentTarget);
  const handleFlightTypeChange = (event, index) => {
    setSelectedFlightType(index);
    setAnchorFlightType(null);
  };
  const handleFlightTypeClose = () => setAnchorFlightType(null);

  const handleClickTripTypeItem = (event) =>
    setAnchorTripType(event.currentTarget);
  const handleTripTypeChange = (event, index) => {
    setSelectedTripType(index);
    setAnchorTripType(null);
  };
  const handleTripTypeClose = () => setAnchorTripType(null);

  const handleTravellerClose = () => setAnchorTraveller(null);
  const handleTravellerDone = ({ adults, children, infants }) => {
    setTravellers({ adults, children, infants });
    setAnchorTraveller(null);
  };

  const handleClickNationalityItem = (event) =>
    setAnchorNationality(event.currentTarget);
  const handleNationalityChange = (event, index) => {
    setSelectedNationality(index);
    setAnchorNationality(null);
  };
  const handleNationalityClose = () => setAnchorNationality(null);

  const handleClickClassItem = (event) => setAnchorClass(event.currentTarget);
  const handleClassChange = (event, index) => {
    setSelectedClass(index);
    setAnchorClass(null);
  };
  const handleClassClose = () => setAnchorClass(null);

  const breadcrumbs = [
    <Stack key="1" spacing={0.5}>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Typography variant="body2" color="neutral.darkgray">
          KTM
        </Typography>
        <BiRightArrowAlt size="1rem" />
        <Typography variant="body2" color="neutral.darkgray">
          LHR
        </Typography>
      </Stack>
      <Link
        underline="hover"
        color="primary.main"
        href="/"
        onClick={handleBreadcrumbClick}
      >
        <Typography variant="body2">Change Flight</Typography>
      </Link>
    </Stack>,
    <Typography key="2" color="common.black" fontWeight="medium">
      Select Return flight
    </Typography>,
  ];

  const [selectedSortIndex, setSelectedSortIndex] = useState(0);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleSortItem = (event) => setAnchorEl(event.currentTarget);

  const [openDateGridDialog, setOpenDateGridDialog] = useState(false);
  const handleDataGridDialogOpen = () => setOpenDateGridDialog(true);

  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const handleFilterDialogOpen = () => setOpenFilterDialog(true);

  const [flightData, setFlightData] = useState(null);

  const handlePrevDate = () => {
    if (flightData && flightData.returnDate)
      if (
        moment(flightData.returnDate).isAfter(new Date(), "day") &&
        moment(flightData.returnDate).isAfter(flightData.departureDate, "day")
      )
        setFlightData({
          ...flightData,
          returnDate: moment(flightData.returnDate).subtract(1, "day"),
        });
  };

  const handleNextDate = () => {
    if (flightData && flightData.returnDate)
      setFlightData({
        ...flightData,
        returnDate: moment(flightData.returnDate).add(1, "day"),
      });
  };

  return (
    <>
      {!mdUp && (
        <ResultHeader
          tripType={TRIP_TYPES[selectedTripType]}
          data={flightData}
        />
      )}

      {mdUp && (
        <Box
          p={1.5}
          bgcolor="common.white"
          sx={{ position: "sticky", top: 0, zIndex: 99 }}
        >
          <Container>
            <Stack direction="row" mb={1} spacing={1}>
              <Dropdown
                id="flight-type-menu"
                data={FLIGHT_TYPES}
                label="Flight Type"
                value={selectedFlightType}
                anchorEl={anchorFlightType}
                open={openFlightType}
                onClose={handleFlightTypeClose}
                onClick={handleClickFlightTypeItem}
                onMenuClick={handleFlightTypeChange}
              />

              <Dropdown
                id="trip-type-menu"
                data={
                  selectedFlightType === FLIGHT_TYPES.indexOf("Domestic Flight")
                    ? // Remove multi cities for domestic flights
                      TRIP_TYPES.filter(
                        (trip_type) => trip_type !== "Multi-city"
                      )
                    : TRIP_TYPES
                }
                label="Trip Type"
                value={selectedTripType}
                anchorEl={anchorTripType}
                open={openTripType}
                onClose={handleTripTypeClose}
                onClick={handleClickTripTypeItem}
                onMenuClick={handleTripTypeChange}
              />

              <TicketsDropdown
                travellers={travellers}
                onClick={handleClickTravellerItem}
                anchorEl={anchorTraveller}
                open={openTraveller}
                onDone={handleTravellerDone}
                onCancel={handleTravellerClose}
              />

              {/* Domestic Flight does not have flight type */}
              {selectedFlightType !==
                FLIGHT_TYPES.indexOf("Domestic Flight") && (
                <Dropdown
                  id="class-menu"
                  data={CLASSES}
                  label="Economy"
                  value={selectedClass}
                  anchorEl={anchorClass}
                  open={openClass}
                  onClose={handleClassClose}
                  onClick={handleClickClassItem}
                  onMenuClick={handleClassChange}
                />
              )}

              <Dropdown
                id="nationality-menu"
                data={NATIONALITIES}
                label="Nationality"
                value={selectedNationality}
                anchorEl={anchorNationality}
                open={openNationality}
                onClose={handleNationalityClose}
                onClick={handleClickNationalityItem}
                onMenuClick={handleNationalityChange}
              />
            </Stack>

            <Box flexGrow={1}>
              {TRIP_TYPES.indexOf("One-way") === selectedTripType && (
                <OneWaySearch
                // onDataChange={setFlightData}
                />
              )}
              {TRIP_TYPES.indexOf("Round Trip") === selectedTripType && (
                <RoundTripSearch onDataChange={setFlightData} />
              )}
              {TRIP_TYPES.indexOf("Multi-city") === selectedTripType && (
                <MultiCitySearch />
              )}
            </Box>
          </Container>
        </Box>
      )}

      <Progress progress={0} loading={true} />

      <Container fixed sx={{ mt: 5, mb: 8 }}>
        <Grid container spacing={7}>
          <Grid
            item
            xs={12}
            lg={3}
            sx={{ display: { xs: "none", lg: "block" } }}
          >
            <Box>
              <Filters />
            </Box>
          </Grid>

          <Grid item xs={12} lg={9}>
            {/* Breadcrumbs */}
            <Box mb={3}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Box>

            <Stack
              direction={{ xs: "column", lg: "row" }}
              mb={3}
              spacing={1}
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", lg: "flex-end" }}
            >
              <Box sx={{ width: "100%" }}>
                <Stack
                  spacing={1}
                  justifyContent={{ xs: "space-between", lg: "flex-start" }}
                  alignItems={{ xs: "center", lg: "flex-start" }}
                  direction={{ xs: "row", lg: "column" }}
                >
                  <Typography variant="h3" fontWeight={"medium"}>
                    Select Return flight
                  </Typography>
                  <Typography
                    variant="body2"
                    color="neutral.gray"
                    width={480}
                    sx={{ display: { xs: "none", lg: "block" } }}
                  >
                    Prices displayed include taxes and may change based on
                    availability. Prices will be finalized once the purchase is
                    completed.
                  </Typography>
                  <Button
                    startIcon={
                      <BiSliderAlt size="20px" color={palette.primary.main} />
                    }
                    sx={{ display: { xs: "flex", lg: "none" } }}
                    onClick={handleFilterDialogOpen}
                  >
                    Filter
                  </Button>

                  {/* fullscreen dialog for filter goes here */}
                  <FullScreenDialog
                    openDialog={openFilterDialog}
                    onDialogChange={setOpenFilterDialog}
                  >
                    <Filters />
                  </FullScreenDialog>
                </Stack>
              </Box>

              <Stack
                direction="row"
                alignItems="stretch"
                spacing={1}
                width={{ xs: "100%", lg: "auto" }}
              >
                {/* prev day button here */}
                <Card
                  elevation={0}
                  sx={{ display: { xs: "block", lg: "none" } }}
                >
                  <CardActionArea
                    sx={{ height: "100%" }}
                    onClick={handlePrevDate}
                  >
                    <Stack
                      direction={"row"}
                      spacing={1}
                      px={1}
                      pr={{ xs: 1, md: 2 }}
                    >
                      <BiChevronLeft color={palette.primary.main} />
                      <Typography
                        fontWeight="medium"
                        sx={{ display: { xs: "none", md: "block" } }}
                      >
                        Prev Day
                      </Typography>
                    </Stack>
                  </CardActionArea>
                </Card>

                <Card elevation={0} sx={{ flexGrow: 1 }}>
                  <CardActionArea
                    sx={{ height: "100%" }}
                    onClick={handleDataGridDialogOpen}
                  >
                    <Stack
                      px={1.5}
                      py={0.875}
                      direction="row"
                      spacing={1.5}
                      alignItems="center"
                      justifyContent={"center"}
                    >
                      <BiTable color={palette.primary.main} />
                      <Typography
                        variant="body1"
                        color="common.black"
                        fontWeight="medium"
                        noWrap
                      >
                        Date Grid
                      </Typography>
                    </Stack>
                  </CardActionArea>
                </Card>

                <DateGrid
                  openDialog={openDateGridDialog}
                  onDialogChange={setOpenDateGridDialog}
                />

                <Card elevation={0} sx={{ flexGrow: 1 }}>
                  <CardActionArea onClick={handleSortItem}>
                    <Stack
                      px={1.5}
                      py={0.875}
                      direction="row"
                      spacing={1.5}
                      justifyContent={"center"}
                      alignItems="center"
                    >
                      <BiSortAlt2 color={palette.primary.main} />
                      <Stack>
                        <Typography
                          variant="body1"
                          color="common.black"
                          fontWeight="medium"
                          noWrap
                        >
                          Sort By
                        </Typography>
                        <Typography variant="body1" color="neutral.gray" noWrap>
                          {options[selectedSortIndex].name} &nbsp;
                          <Box
                            component={"span"}
                            display={{ xs: "none", sm: "inline-block" }}
                          >
                            Flight
                          </Box>
                        </Typography>
                      </Stack>
                      <Box
                        component={"span"}
                        display={{ xs: "none", sm: "block" }}
                      >
                        <BiChevronDown />
                      </Box>
                    </Stack>
                  </CardActionArea>
                </Card>
                <SortMenu
                  anchorEl={anchorEl}
                  onAnchorEl={setAnchorEl}
                  index={selectedSortIndex}
                  onIndexChange={setSelectedSortIndex}
                  options={options}
                />

                {/* next day button here */}
                <Card
                  elevation={0}
                  sx={{ display: { xs: "block", lg: "none" } }}
                  onClick={handleNextDate}
                >
                  <CardActionArea sx={{ height: "100%" }}>
                    <Stack direction={"row"} px={1} pl={{ xs: 1, md: 2 }}>
                      <Typography
                        fontWeight="medium"
                        mr={1}
                        sx={{ display: { xs: "none", md: "block" } }}
                      >
                        Next Day
                      </Typography>
                      <BiChevronRight color={palette.primary.main} />
                    </Stack>
                  </CardActionArea>
                </Card>
              </Stack>
            </Stack>

            <Stack spacing={1.5}>
              <FlightCard />
              <FlightCard />

              <Paper
                elevation={0}
                sx={{ borderRadius: "8px", overflow: "hidden", mb: 3 }}
                className="st-flight-combination"
              >
                <FlightCard />
                <FlightCard />
              </Paper>
            </Stack>

            <Stack alignItems="center" mt={4}>
              <Button variant="outlined" fullWidth={!mdUp}>
                Load More...
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default Result;
