import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const ButtonSemiLarge = styled(Button)(({ theme }) => ({
  boxShadow: 'none',
  paddingTop: 12,
  paddingBottom: 12,
  '&:hover': {
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
  },
  '&:focus': {},
}));
