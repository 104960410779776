import { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { SincereCard, StyledInput, FlexBox } from "../../../components/_shared";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { ButtonSemiLarge } from "./Profile.styled";

const Input = styled("input")({ display: "none" });

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const EditBasic = ({ onFinish, data }) => {
  const [nationality, setNationality] = useState(data.nationality);
  const handleNationalityChange = (event) => setNationality(event.target.value);

  const [gender, setGender] = useState(data.gender);
  const handleGenderChange = (event) => setGender(event.target.value);

  const [dobMonth, setDobMonth] = useState(moment(data.dob).format("MMMM"));
  const handleDobMonthChange = (event) => setDobMonth(event.target.value);

  const handleSave = () => {
    // your save logic
    onFinish(); // ? close editing form
  };

  return (
    <>
      <Typography variant="h2" fontWeight="medium" mb={4}>
        Edit Basic Information
      </Typography>
      <SincereCard sx={{ mb: { xs: "24px", md: "32px" } }}>
        <SincereCard.Header>
          <Typography variant="h4" sx={{ fontWeight: "medium" }}>
            Basic Information
          </Typography>
        </SincereCard.Header>
        <SincereCard.Body>
          <Grid container columns={12} spacing={2.5}>
            <Grid item xs={12}>
              <Stack direction="row" spacing="20px" alignItems="center">
                <Avatar
                  alt={data.name}
                  src={data.img}
                  sx={{ width: 96, height: 96 }}
                />
                <label htmlFor="change-image">
                  <Input
                    accept="image/*"
                    id="change-image"
                    multiple
                    type="file"
                  />
                  <Button variant="text" component="span">
                    Change Image
                  </Button>
                </label>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <StyledInput
                  id="fname"
                  type="text"
                  defaultValue={data.name.split(" ")[0]}
                />
                <InputLabel htmlFor="fname">First Name</InputLabel>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <StyledInput
                  id="lname"
                  type="text"
                  defaultValue={data.name.split(" ")[1]}
                />
                <InputLabel htmlFor="lname">Last Name</InputLabel>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Select
                  labelId="nationality-select-label"
                  id="nationality"
                  value={nationality}
                  onChange={handleNationalityChange}
                  input={<StyledInput />}
                >
                  <MenuItem value={"Nepalese"}>Nepalese</MenuItem>
                  <MenuItem value={"indian"}>indian</MenuItem>
                  <MenuItem value={"Others"}>Others</MenuItem>
                </Select>
                <InputLabel htmlFor="nationality-select-label">
                  Nationality
                </InputLabel>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Select
                  labelId="gender-select-label"
                  id="gender"
                  value={gender}
                  onChange={handleGenderChange}
                  input={<StyledInput />}
                >
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value={"Female"}>Female</MenuItem>
                  <MenuItem value={"Others"}>Others</MenuItem>
                </Select>
                <InputLabel htmlFor="gender-select-label">Gender</InputLabel>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={10} md={6}>
              <FlexBox fd="column-reverse">
                <FlexBox
                  mt={0.875}
                  items="center"
                  gap="8px"
                  sx={{ width: "100%" }}
                >
                  <StyledInput
                    sx={{ width: "80px" }}
                    id="date"
                    type="text"
                    placeholder="DD"
                    defaultValue={moment(data.dob).format("DD")}
                  />
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{ color: "neutral.gray" }}
                  >
                    /
                  </Typography>
                  <Select
                    labelId="dobMonth-select-label"
                    id="dobMonth"
                    value={dobMonth}
                    onChange={handleDobMonthChange}
                    input={<StyledInput />}
                    sx={{ flex: "1", width: "100%" }}
                  >
                    {MONTHS.map((month, index) => (
                      <MenuItem value={month} key={index}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{ color: "neutral.gray" }}
                  >
                    /
                  </Typography>
                  <StyledInput
                    sx={{ width: "90px" }}
                    id="year"
                    type="text"
                    placeholder="YYYY"
                    defaultValue={moment(data.dob).format("YYYY")}
                  />
                </FlexBox>
                <Typography
                  variant="body1"
                  component="label"
                  sx={{ color: "neutral.darkgray" }}
                >
                  Date of Birth
                </Typography>
              </FlexBox>
            </Grid>
          </Grid>
        </SincereCard.Body>
      </SincereCard>
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <ButtonSemiLarge variant="outlined" onClick={onFinish}>
          Cancel
        </ButtonSemiLarge>
        <ButtonSemiLarge variant="contained" onClick={handleSave}>
          Save Changes
        </ButtonSemiLarge>
      </Stack>
    </>
  );
};

export default EditBasic;
