import { useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import { StyledToggleButtonGroup } from "./index.elements";
import { Dropdown, TicketsDropdown } from "../../../components/_shared";
import {
  OneWaySearch,
  RoundTripSearch,
  MultiCitySearch,
} from "../../../components/FlightSearchTool";

const NATIONALITIES = ["American", "Nepali", "Ukrainian"];

const CLASSES = ["Economy", "Premium Economy", "Business", "First Class"];

const InternationalFlight = () => {
  const [anchorTraveller, setAnchorTraveller] = useState(null);
  const openTraveller = Boolean(anchorTraveller);

  const [anchorNationality, setAnchorNationality] = useState(null);
  const [selectedNationality, setSelectedNationality] = useState(null);
  const openNationality = Boolean(anchorNationality);

  const [anchorClass, setAnchorClass] = useState(null);
  const [selectedClass, setSelectedClass] = useState(0);
  const openClass = Boolean(anchorClass);

  const [tripType, setTripType] = useState("one");

  const handleClickTravellerItem = (event) =>
    setAnchorTraveller(event.currentTarget);

  const [travellers, setTravellers] = useState({
    adults: 1,
    children: 0,
    infants: 0,
  });

  const handleTravellerClose = () => setAnchorTraveller(null);
  const handleTravellerDone = ({ adults, children, infants }) => {
    setTravellers({ adults, children, infants });
    setAnchorTraveller(null);
  };

  const handleClickNationalityItem = (event) =>
    setAnchorNationality(event.currentTarget);
  const handleNationalityChange = (event, index) => {
    setSelectedNationality(index);
    setAnchorNationality(null);
  };
  const handleNationalityClose = () => setAnchorNationality(null);

  const handleClickClassItem = (event) => setAnchorClass(event.currentTarget);
  const handleClassChange = (event, index) => {
    setSelectedClass(index);
    setAnchorClass(null);
  };
  const handleClassClose = () => setAnchorClass(null);

  const handleChange = (event, newAlignment) => setTripType(newAlignment);

  return (
    <Box sx={{ background: "#fff", borderRadius: "0 8px 8px 8px" }} p={2}>
      <Stack spacing={2}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
          }}
        >
          <StyledToggleButtonGroup
            color="primary"
            value={tripType}
            exclusive
            onChange={handleChange}
            sx={{ mb: { xs: 1, md: "unset" } }}
          >
            <ToggleButton value="one" disabled={tripType === "one"}>
              One-way
            </ToggleButton>
            <ToggleButton value="round" disabled={tripType === "round"}>
              Round trip
            </ToggleButton>
            <ToggleButton value="multi" disabled={tripType === "multi"}>
              Multi-city
            </ToggleButton>
          </StyledToggleButtonGroup>

          <Stack direction="row" spacing={1}>
            <TicketsDropdown
              travellers={travellers}
              onClick={handleClickTravellerItem}
              anchorEl={anchorTraveller}
              open={openTraveller}
              onDone={handleTravellerDone}
              onCancel={handleTravellerClose}
            />

            <Dropdown
              id="class-menu"
              data={CLASSES}
              label="Economy"
              value={selectedClass}
              anchorEl={anchorClass}
              open={openClass}
              onClose={handleClassClose}
              onClick={handleClickClassItem}
              onMenuClick={handleClassChange}
            />

            <Dropdown
              id="nationality-menu"
              data={NATIONALITIES}
              label="Nationality"
              value={selectedNationality}
              anchorEl={anchorNationality}
              open={openNationality}
              onClose={handleNationalityClose}
              onClick={handleClickNationalityItem}
              onMenuClick={handleNationalityChange}
            />
          </Stack>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          {tripType === "one" && <OneWaySearch />}

          {tripType === "round" && <RoundTripSearch />}

          {tripType === "multi" && <MultiCitySearch />}
        </Box>
      </Stack>
    </Box>
  );
};

export default InternationalFlight;
