import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import InfoIcon from '../../assets/icons/Info.icon';
import Link from '@mui/material/Link';

export const CovidInfo = () => {
  return (
    <Paper
      elevation={0}
      sx={{ borderRadius: '8px', mb: 4, overflow: 'hidden' }}
    >
      <Stack
        direction={'row'}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        spacing={1.5}
        p={2.5}
      >
        <Box>
          <InfoIcon />
        </Box>
        <Typography color={'neutral.darkgray'}>
          <Link href="#" color={'common.black'}>
            <Typography component="span" fontWeight={'medium'}>
              COVID-19 updates
            </Typography>
          </Link>{' '}
          — Find out the details about testing and quarantine rules for your
          journey.
        </Typography>
      </Stack>
    </Paper>
  );
};
