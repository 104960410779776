import React from 'react';
import { FlexBox, SincereCard } from '../../../components/_shared';
import Typography from '@mui/material/Typography';

const PriceSection = () => {
  return (
    <>
      <SincereCard sx={{ mb: { xs: '16px', md: '24px' } }}>
        <SincereCard.Header>
          <Typography variant="h4" sx={{ fontWeight: 'medium' }}>
            Price Summary
          </Typography>
        </SincereCard.Header>
        <SincereCard.Body>
          <FlexBox gap="16px" fd="column">
            <FlexBox justify="space-between" sx={{ width: '100%' }}>
              <Typography variant="body1" sx={{ color: 'neutral.darkgray' }}>
                Trip Total (NPR)
              </Typography>
              <Typography variant="body1" sx={{ color: 'neutral.darkgray' }}>
                40,000/-
              </Typography>
            </FlexBox>

            <FlexBox justify="space-between" sx={{ width: '100%' }}>
              <Typography variant="body1" sx={{ color: 'neutral.darkgray' }}>
                Trip Total (NPR)
              </Typography>
              <Typography variant="body1" sx={{ color: 'neutral.darkgray' }}>
                40,000/-
              </Typography>
            </FlexBox>
          </FlexBox>
        </SincereCard.Body>
        <SincereCard.Footer>
          <FlexBox justify="space-between" sx={{ width: '100%' }}>
            <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
              Trip Total (NPR)
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
              40,000/-
            </Typography>
          </FlexBox>
        </SincereCard.Footer>
      </SincereCard>

      <Typography mb={3} variant="body2" sx={{ color: 'neutral.gray' }}>
        Prices displayed include taxes and may change based on availability.
        Prices will be finalized once the purchase is completed. Any additional
        fees can be reviewed before payment.
      </Typography>
    </>
  );
};

export default PriceSection;
