import { styled } from '@mui/material/styles';

export const Dot = styled('div')(
  ({ theme, size, mx, bg }) => `
  height: ${size || '4px'};
  width: ${size || '4px'};
  margin-left: ${mx || '8px'};
  margin-right: ${mx || '8px'};
  background-color: ${bg || theme.palette.common.black};
  border-radius: 50%;
`
);
