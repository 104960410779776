import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { CustomIconButton } from "./FlightSearchTool.styled";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  BiMap,
  BiSearch,
  BiRadioCircle,
  BiCalendarAlt,
  BiChevronLeft,
  BiChevronRight,
} from "react-icons/bi";

import moment from "moment";

import { Autocomplete, StyledDatePicker, StyledDateInput } from "../_shared";
import { StyledButton } from "./FlightSearchTool.styled";

const AIRPORTS = [
  {
    city: "Kathmandu, Nepal",
    name: "Tribhuwan International Airport",
    code: "KTM",
  },
  {
    city: "London, United Kingdom",
    name: "London Heathrow Airport",
    code: "LHR",
  },
  {
    city: "London, United Kingdom",
    name: "London Stansted Airport",
    code: "STN",
  },
  {
    city: "London, United Kingdom",
    name: "London Gatwick Airport",
    code: "LGW",
  },
  { city: "London, United Kingdom", name: "London Luton Airport", code: "LTN" },
  { city: "London, United Kingdom", name: "London City Airport", code: "LCY" },
  {
    city: "London, United Kingdom",
    name: "London Southend Airport",
    code: "SEN",
  },
];

export const OneWaySearch = ({ onDataChange = (data) => {} }) => {
  const { breakpoints, palette } = useTheme();
  const navigate = useNavigate();
  const smUp = useMediaQuery(breakpoints.up("sm"));

  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const toRef = useRef(null);
  const [departureDate, setDepartureDate] = useState(null);
  const [departureDateOpen, setDepartureDateOpen] = useState(false);
  const [disableDepartureDate, setDisableDepartureDate] = useState(false);

  useEffect(() => {
    onDataChange({ from, to, departureDate });
  }, [from, to, departureDate, onDataChange]);

  const openDepartureCalendar = () => {
    if (!disableDepartureDate) setDepartureDateOpen(true);
  };

  const incrementDate = () =>
    setDepartureDate(moment(departureDate).add(1, "day"));

  const decrementDate = () => {
    if (moment(departureDate).isAfter(new Date(), "day"))
      setDepartureDate(moment(departureDate).subtract(1, "day"));
  };

  const handleSearch = () => {
    navigate("/result");
  };

  const handleFromChange = (e, value) => {
    setFrom(value);

    // ? first child is a wrapper, 2nd grandchild is the input
    toRef.current?.children[0].children[1].focus();
  };

  return (
    <Grid container columnSpacing={2} rowSpacing={1.5}>
      <Grid item xs={12} md={6}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 1.5, md: 1 }}
        >
          <Autocomplete
            id="from"
            placeholder="Where From?"
            options={AIRPORTS}
            value={from}
            startAdornment={
              <BiRadioCircle size={20} style={{ flexShrink: 0 }} />
            }
            onChange={handleFromChange}
          />

          <Autocomplete
            ref={toRef}
            id="to"
            options={AIRPORTS}
            placeholder="Where To?"
            startAdornment={<BiMap size={20} style={{ flexShrink: 0 }} />}
            value={to}
            onChange={(e, value) => setTo(value)}
          />
        </Stack>
      </Grid>

      <Grid item xs={12} md={4}>
        <StyledDatePicker
          toolbarTitle="Select Departure Date"
          open={departureDateOpen}
          onClose={() => setDepartureDateOpen(false)}
          value={departureDate}
          onChange={setDepartureDate}
          renderInput={({ inputRef, inputProps }) => (
            <StyledDateInput
              inputRef={inputRef}
              inputProps={{ ...inputProps, placeholder: "Departure Date" }}
              onClick={openDepartureCalendar}
              fullWidth
              sx={{ paddingRight: 1 }}
              startAdornment={
                <BiCalendarAlt size={20} style={{ flexShrink: 0 }} />
              }
              endAdornment={
                smUp && departureDate ? (
                  <Stack
                    direction="row"
                    onMouseEnter={() => setDisableDepartureDate(true)}
                    onMouseLeave={() => setDisableDepartureDate(false)}
                  >
                    <CustomIconButton onClick={decrementDate}>
                      <BiChevronLeft size={22} />
                    </CustomIconButton>
                    <CustomIconButton onClick={incrementDate}>
                      <BiChevronRight size={22} />
                    </CustomIconButton>
                  </Stack>
                ) : null
              }
            />
          )}
        />
      </Grid>

      <Grid
        item
        xs={12}
        md={2}
        alignItems="flex-end"
        justifyContent="flex-end"
        mt={{ xs: 1, md: 0 }}
        minHeight={{ xs: 60, md: "unset" }}
      >
        <StyledButton
          startIcon={<BiSearch color={palette.common.white} size="1.25rem" />}
          onClick={handleSearch}
        >
          Search
        </StyledButton>
      </Grid>
    </Grid>
  );
};

export default OneWaySearch;
