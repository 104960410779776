import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const InfoRow = ({ title, children }) => {
  return (
    <Box>
      <Stack p={2.5} direction="row" spacing={2} alignItems="center">
        <Box sx={{ flexBasis: { xs: '100px', sm: '186px' } }}>
          <Typography variant="body1" color="neutral.gray">
            {title}
          </Typography>
        </Box>
        <Box>{children}</Box>
      </Stack>
    </Box>
  );
};

export default InfoRow;
