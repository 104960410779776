import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

// Dialog
export const SincereDialog = styled(Dialog)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    "& .MuiDialogContent-root": {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(6),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
  },
}));

export const SincereDialogTitle = styled(DialogTitle)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}));

// Table
export const DateGridTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => true,
})(({ theme, isActive, isSelected }) => ({
  [`&.${tableCellClasses.head}, &.header`]: {
    backgroundColor: theme.palette.neutral.offgray,
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.neutral.stroke}`,
    fontWeight: theme.typography.fontWeightMedium,
    minWidth: 111,

    [theme.breakpoints.down("sm")]: {
      padding: "11px 0",
      minWidth: 94,
      fontSize: 14,
    },
  },
  [`&.${tableCellClasses.body}`]: {
    minWidth: 111,
    fontSize: 14,
    border: `1px solid ${theme.palette.neutral.stroke}`,
    ...(isSelected ? { backgroundColor: theme.palette.primary.main } : {}),
    color: isSelected ? theme.palette.common.white : theme.palette.common.black,

    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
      minWidth: 94,
      fontSize: 12,
    },
  },

  "&.column-sticky": {
    position: "sticky",
    right: 0,
    "&:after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      content: '""',
      boxShadow: theme.shadows[2],
    },
  },
}));

export const DateGridTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
